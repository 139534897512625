import React from 'react';
import ContentLoader from 'react-content-loader';
import s from './ServiceInCatalog.module.css';

const BeautyPostsLoadingBlock = () => {
  return (
    <ContentLoader
      speed={2}
      className={s.item_wrap_app}
      width={800}
      height={'100%'}
      backgroundColor="#f3f3f3"
      foregroundColor="#d6d6d6">
      <rect x="-5" y="0" rx="0" ry="0" width="435" height="200" />
    </ContentLoader>
  );
};

export default BeautyPostsLoadingBlock;
