import React, { useState } from 'react';
import s from '../../../companents/beauty/ItemClientReq/req.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import objToFd from '../../../common/helpers/objToFd';
import beautyApi from '../../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { accountMasterServices } from '../../../redux/action';

const ModalEditServices = ({ category, page }) => {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();

  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const [subServices, setSubServices] = useState(null);
  const [services, setServices] = useState(null);
  const [priceService, setPriceService] = useState(null);

  const login = JSON.parse(localStorage.getItem('account'));

  const fetchServices = async (value) => {
    const fd = new FormData();
    fd.append('prof_id', value);
    const categoryes = await beautyApi.fetchServicesMaster(fd);
    setServices(categoryes);
  };

  const fetchSubServices = (value) => {
    const tservices = beautyApi.fetchServicesInReq3(value).then((res) => {
      return res;
    });
    tservices.then((res) => setSubServices(res));
  };

  const wave_succes = () =>
    toast.success('Услуга успешно добавлена!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
      autoClose: 500,
      progress: undefined,
    });

  const wave_error = () =>
    toast.error('Такая услуга уже добавлена, выберите другую!', {
      backgroundColor: 'red',
      color: '#ffffff',
      autoClose: 5000,
      progress: undefined,
    });

  const onSubmit = async (data) => {
    console.log(data);
    const obj = {
      ...data,
      profStatus: 1,
      masterId: accountBeautyInfo.id,
      servicesName:
        document.getElementById('services').options[
          document.getElementById('services').selectedIndex
        ].text,
      catName:
        document.getElementById('category').options[
          document.getElementById('category').selectedIndex
        ].text,
      servicesId_add_name:
        document.getElementById('subServices').options[
          document.getElementById('subServices').selectedIndex
        ].text !== 'Все услуги'
          ? document.getElementById('subServices').options[
              document.getElementById('subServices').selectedIndex
            ].text
          : '',
    };
    if (obj.catName === 'Категория услуги') {
      toast.error('Необходимо выбрать категорию услуги!', {
        backgroundColor: 'red',
        color: '#ffffff',
        autoClose: 5000,
        progress: undefined,
      });
    } else if (data.servicesId === 'Все услуги') {
      toast.error('Необходимо выбрать  услугу!', {
        backgroundColor: 'red',
        color: '#ffffff',
        autoClose: 5000,
        progress: undefined,
      });
    } else if (data.servicesId_add === '-') {
      toast.error('Необходимо выбрать под услугу!', {
        backgroundColor: 'red',
        color: '#ffffff',
        autoClose: 5000,
        progress: undefined,
      });
    } else {
      const fd = new FormData();
      await objToFd(fd, obj);
      const service = await beautyApi.addCategoryMaster(fd);
      if (service) {
        wave_succes();
        if (login) {
          const regdata = new FormData();

          regdata.append('phone', login.login);
          regdata.append('pass', login.pass);
          regdata.append('status', 1);

          const res = beautyApi.login(regdata);
          res.then((res) => {
            dispatch(accountMasterServices(res[1]));
          });
        }
        localStorage.removeItem('applications');
        localStorage.removeItem('app_length');
      } else {
        wave_error();
      }
    }
  };
  const handleServices = (value) => {
    fetchServices(value);
  };

  const handleSubServices = (value) => {
    fetchSubServices(value);
  };
  return (
    <Popup trigger={runModal()} modal nested>
      {(close) => (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className={s.modal}>
            <button className={s.close} onClick={close}>
              &times;
            </button>
            <div className={s.header}>
              Добавление <br /> оказываемых услуг
            </div>
            <div
              className={s.content_edit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <select
                name="category"
                id="category"
                {...register('catId', {
                  required: 'Поле обязательно к заполнению',
                })}
                onChange={(e) => handleServices(e.target.value)}>
                <option>Категория услуги</option>
                {category?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.prof}
                  </option>
                ))}
              </select>

              <select
                name="services"
                id="services"
                {...register('servicesId', {
                  required: 'Поле обязательно к заполнению',
                })}
                onChange={(e) => {
                  handleSubServices(e.target.value);
                }}>
                <option selected={true} disabled>
                  Все услуги
                </option>
                {services?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.prof_add}
                  </option>
                ))}
              </select>
              <select name="subServices" id="subServices" {...register('servicesId_add')}>
                <option selected={true} disabled>
                  -
                </option>
                {subServices?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.prof_add_dop}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Цена услуги"
                {...register('priceServices', {
                  required: 'Поле обязательно к заполнению',
                })}
              />
            </div>

            <button className={s.beauty_button_confirm}>Добавить</button>
          </div>
          <ToastContainer position="top-center" autoClose={500} />
        </form>
      )}
    </Popup>
  );

  function runModal() {
    return page == 'main' ? (
      <button
        // onClick={() => setEditOnService((editOnService) => !editOnService)}
        style={{ fontSize: '9px', marginLeft: '0px' }}>
        Добавить услугу
      </button>
    ) : page == 'editPage' ? (
      <div className={s.addNewService}>
        <span
          style={{ color: '#00e300', fontSize: '20px', paddingLeft: '10px', marginBottom: '20px' }}>
          &#43;
        </span>{' '}
        Добавить услугу
      </div>
    ) : null;
  }
};

export default ModalEditServices;
