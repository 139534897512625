import s from "./schedule.module.css";
import GeneralCalendar from "../GeneralCalendar/GeneralCalendar";
import "./Calendar.css";
import ScheduleRecord from "./ScheduleRecord";

const Schedule = () => {
  return (
    <div className={s.root}>
      <div>
        <GeneralCalendar />
      </div>

      <ScheduleRecord />
    </div>
  );
};

export default Schedule;