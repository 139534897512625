import React, { useState, useRef, useEffect } from 'react';
import { confirm } from 'react-confirm-box';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import beautyApi from '../../../api/beautyApi';
import { accountBeauty, accountMasterServices } from '../../../redux/action';
import s from '../pageMaster.module.css';
import no_avatar from '../style/no-avatar.png';
import ModalEditServices from './ModalEditServices';
import uniqid from 'uniqid';
import ImageResize from 'image-resize';
import useReactIpLocation from 'react-ip-details';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect,
} from 'react-device-detect';

const ProfileMasterEdit = () => {
  const dispatch = useDispatch();
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const accountMasterServicesD = useSelector((state) => state.ServicesMaster);
  const avatar = useRef();
  const history = useHistory();
  const targetScroll = useRef(null);
  const [category, setCategory] = useState(null);
  const [newServrices, setNewServices] = useState([]);
  const [editOnService, setEditOnService] = useState(false);
  const login = JSON.parse(localStorage.getItem('account'));
  const [changeName, setChangeName] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeSalon, setChangeSalon] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [cities, setCities] = useState([{}]);

  const getDelServiceTarget = useRef(null);
  const time = localStorage.getItem('time');
  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });

  const [isBalance, setIsBalance] = useState(false);

  const fetchCategoryes = async () => {
    const categoryes = await beautyApi.fetchCategoryesMaster();
    setCategory(categoryes);
  };

  useEffect(() => {
    getCityList();
  }, []);
  const getCityList = async () => {
    let cities = await fetch('https://beauty.bikli.kz/beautyApi/getListOfCities.php');
    cities = await cities.json();
    setCities(cities);
  };

  useEffect(() => {
    fetchCategoryes();
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    servicesMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountMasterServicesD]);

  useEffect(() => {
    setIsBalance(true);
  }, [accountBeautyInfo.balance]);

  const editData = async () => {
    if (avatar.current.files[0]) {
      const form = new FormData();
      var imageResize = new ImageResize({
        format: 'png',
        width: 150,
      });
      let res = await imageResize.play(avatar.current.files[0]);

      // form.append('userfile', avatar.current.files[0], avatar.current.files[0].name);
      form.append('userfile', res);
      form.append('id', accountBeautyInfo.id);
      const uploadAvatar = beautyApi.uploadAvatarUser(form);
      uploadAvatar.then((res) => res).then((res) => res.status === 200 && window.location.reload());
    }
  };

  const servicesMaster = () => {
    let arrDone = [];

    // eslint-disable-next-line array-callback-return
    accountMasterServicesD.sort().map((item) => {
      const services = accountMasterServicesD.filter(
        (itemServices) => itemServices.cat_name === item.cat_name,
      );
      arrDone = [...arrDone, { name: item.cat_name, services: services }];
    });
    const uniqueArray = arrDone.filter((thing, index) => {
      return (
        index ===
        arrDone.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(thing);
        })
      );
    });
    setNewServices(uniqueArray);
  };

  const customRender = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className={s.wrapModalConfirm}>
          <div className={s.modalConfirm}>
            <div className={s.modalConfirmMessage}> {message} </div>
            <div className={s.modalConfirmButton}>
              <button onClick={onConfirm}> Да </button>
              <button onClick={onCancel}> Нет </button>
            </div>
          </div>
        </div>
      );
    },
  };

  const deleteProfile = async (options) => {
    const result = await confirm('Вы дейстивтельно хотите удалить аккаунт?', options);
    if (result) {
      const fd = new FormData();
      fd.append('id', accountBeautyInfo.id);
      beautyApi.deleteAccount(fd);
      history.push('/');
      localStorage.removeItem('account');
      dispatch(accountBeauty([]));
      return;
    }
  };

  const deleteService = async (target, options) => {
    const result = await confirm('Вы дейстивтельно хотите удалить услугу?', options);
    if (result) {
      const masterId = target.getAttribute('data-masterid');
      const idApp = target.getAttribute('data-idapp');
      const service = target.getAttribute('data-service');
      const fd = new FormData();
      fd.append('id', idApp);
      fd.append('masterId', masterId);
      fd.append('servicesName', service);
      fd.append('profStatus', 0);
      const res = await beautyApi.addCategoryMaster(fd);
      if (res) {
        if (login) {
          const regdata = new FormData();

          regdata.append('phone', login.login);
          regdata.append('pass', login.pass);
          regdata.append('status', 1);

          beautyApi.login(regdata).then((res) => {
            if (res) {
              dispatch(accountMasterServices(res[1]));
            }
          });
          localStorage.removeItem('applications');
          localStorage.removeItem('app_length');

          return;
        }
      }
    }
  };
  const exitAccount = async (e, options) => {
    const result = await confirm('Вы дейстивтельно хотите выйти ?', options);
    if (result) {
      e.preventDefault();
      const fd = new FormData();
      fd.append('id', accountBeautyInfo.id);
      fd.append('time', time);
      fd.append('ip', ipResponse.IPv4);
      fd.append('osName', osName);
      fd.append('osVersion', osVersion);
      fd.append('mobileVendor', mobileVendor);
      fd.append('mobileModel', mobileModel);
      beautyApi.logout(fd);
      localStorage.removeItem('account');
      localStorage.removeItem('applications');
      localStorage.removeItem('applications_master');
      localStorage.removeItem('notification_master');
      localStorage.removeItem('id_master');
      localStorage.removeItem('app_length');
      localStorage.removeItem('time');
      dispatch(accountBeauty([]));

      history.push('/');
    }
  };

  const saveEditData = async () => {
    let name, salon, address, city;
    changeName ? (name = changeName) : (name = accountBeautyInfo.FIO);
    changeSalon ? (salon = changeSalon) : (salon = accountBeautyInfo.SalonName);
    changeAddress ? (address = changeAddress) : (address = accountBeautyInfo.Adress);
    changeCity ? (city = changeCity) : (city = accountBeautyInfo.city);
    const fd = new FormData();
    fd.append('name', name);
    fd.append('salon', salon);
    fd.append('adress', address);
    fd.append('city_id', city);
    fd.append('masterId', accountBeautyInfo.id);
    const send = await beautyApi.saveEditAccount(fd);
    if (send) {
      if (login) {
        const regdata = new FormData();

        regdata.append('phone', login.login);
        regdata.append('pass', login.pass);
        regdata.append('status', 1);

        const res = beautyApi.login(regdata);
        res.then((res) => {
          dispatch(accountBeauty(res[0]));
          dispatch(accountMasterServices(res[1]));
          toast.success('Данные изменены', {
            position: 'top-center',
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setChangeAddress(false);
          setChangeName(false);
          setChangeSalon(false);
        });
        return;
      }
    }
  };

  return (
    <div>
      <>
        <ToastContainer
          position="top-center"
          autoClose={500}
          newestOnTop={false}
          closeOnClick
          hideProgressBar
          rtl={false}
          draggable
        />
        <div className={s.wrap_title_edit}>
          <div className={s.titleEditProfile} style={{ marginLeft: '23px' }}>
            Редактировать профиль
          </div>
          {changeName || changeSalon || changeAddress || changeCity ? (
            <div className={s.saveButton}>
              <button
                onClick={() => {
                  saveEditData();
                }}>
                Сохранить
              </button>
            </div>
          ) : null}
        </div>
        <div className={s.headerEdit} ref={targetScroll}>
          <label htmlFor="file-input">
            <div className={s.avatarEdit}>
              {accountBeautyInfo.img && accountBeautyInfo.img !== '' ? (
                <img
                  src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeautyInfo.img}`}
                  alt="avatar"
                />
              ) : (
                <img src={no_avatar} alt="avatar" />
              )}
            </div>
            <span>Изменить фото профиля</span>
          </label>
        </div>
        <div className={s.infoEdit}>
          <div className={s.labelInfoMaster}>
            <div>Имя</div>
            <div>Адрес</div>
            <div>
              Название <br /> салона
            </div>
            <div>Город</div>
            <div>Баланс</div>
            <div>Услуги</div>
          </div>
          <div className={s.masterEdit}>
            <div className={s.info_edit_master} style={{ marginTop: '0px' }}>
              <div className={s.balanceEdit}>
                <input
                  type="text"
                  name="changeName"
                  id="changeName"
                  defaultValue={accountBeautyInfo.FIO}
                  onChange={(e) => setChangeName(e.target.value)}
                />
                <div className={s.editPen}>&#9998;</div>
              </div>
            </div>
            {/* <div className={s.proffesion_person}> мастер</div> */}

            <div className={s.info_edit_master}>
              <div className={s.balanceEdit}>
                <input
                  type="text"
                  defaultValue={accountBeautyInfo.Adress}
                  onChange={(e) => setChangeAddress(e.target.value)}
                />

                <div className={s.editPen}>&#9998;</div>
              </div>
            </div>
            <div className={s.info_edit_master}>
              <div className={s.balanceEdit}>
                <input
                  type="text"
                  defaultValue={accountBeautyInfo.SalonName}
                  onChange={(e) => setChangeSalon(e.target.value)}
                />
                <div className={s.editPen}>&#9998;</div>
              </div>
            </div>
            <div className={s.info_edit_master} style={{ marginTop: 10 }}>
              <div className={s.balanceEdit}>
                <select onChange={(e) => setChangeCity(e.target.value)}>
                  <option value="0">Не указано</option>
                  {cities.map((item) => (
                    <option
                      selected={item.city_id == accountBeautyInfo.city ? 'selected' : ''}
                      key={item.city_id}
                      value={item.city_id}>
                      {item.city}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className={s.info_edit_master}
              onClick={(e) => history.push('/beauty/master/addbalance')}>
              <div className={s.balanceEdit}>
                {(isBalance && accountBeautyInfo.balance === null) || accountBeautyInfo.balance <= 0
                  ? 'Пополните баланс'
                  : accountBeautyInfo.balance}
                <div className={s.editPen} style={{ fontSize: '25px' }}>
                  &#43;
                </div>
              </div>
            </div>
            <div className={s.info_edit_master} style={{ marginTop: 10 }}>
              <div className={s.serviceEdit}>
                {newServrices.length
                  ? newServrices.map((item, key) => {
                      return (
                        <div key={uniqid()}>
                          <div className={s.category_name}>{item.name}</div>
                          <div>
                            {newServrices[key].services.map((itemServ) => (
                              <div
                                className={s.activeClick}
                                onClick={(e) =>
                                  editOnService && deleteService(e.target, customRender)
                                }
                                key={uniqid()}>
                                {editOnService && (
                                  <div
                                    className={s.editIcon}
                                    data-idapp={itemServ.id}
                                    data-masterid={itemServ.masterId}
                                    data-service={itemServ.services_name}>
                                    &#10006;
                                  </div>
                                )}
                                <span
                                  className={s.services_name}
                                  data-idapp={itemServ.id}
                                  data-masterid={itemServ.masterId}
                                  data-service={itemServ.services_name}>
                                  {itemServ.services_name !== 'Выбрать услугу' ? (
                                    <>
                                      {itemServ.services_name}
                                      {itemServ.priceServices !== '0' &&
                                        itemServ.serives_add_id_names === 'Все услуги' &&
                                        `- ${itemServ.priceServices} тг.`}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </span>
                                <span className={s.services_add_name}>
                                  {itemServ.serives_add_id_names !== 'Все услуги' ? (
                                    <>
                                      {itemServ.serives_add_id_names}
                                      {itemServ.priceServices !== '0' &&
                                        `- ${itemServ.priceServices} тг.`}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })
                  : 'Оказываемые услуги не выбраны'}
                {editOnService && <ModalEditServices page={'editPage'} category={category} />}
              </div>
            </div>
          </div>
        </div>
        <input
          id="file-input"
          type="file"
          name="file"
          multiple
          style={{ display: 'none' }}
          ref={avatar}
          onChange={() => editData()}
        />

        <div className={s.exitButton}>
          <button
            onClick={() => setEditOnService((editOnService) => !editOnService)}
            style={{ fontSize: '9px', marginLeft: '0px' }}>
            Редактировать оказываемые услуги
          </button>
          {/* <button onClick={(e) => history.push('/beauty/master/addbalance')}>
            Пополнить баланс
          </button> */}
          <button onClick={(e) => exitAccount(e, customRender)}>Выйти</button>
        </div>

        {/* <div className={s.exitButton} style={{ paddingBottom: '100px' }}>
          <div
            style={{ marginLeft: '0', color: 'blue' }}
            onClick={() => {
              deleteProfile(customRender);
            }}>
            Удалить профиль
          </div>
        </div> */}
      </>
    </div>
  );
};

export default ProfileMasterEdit;
