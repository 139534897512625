const baseBeautyUrl = 'https://bshop.kz/beauty/beautyApi/';

const beautyApi = {
  fetchMastersInCatalog: async (counItems, profName) => {
    const response = await fetch(
      baseBeautyUrl + `getCategory.php?start=0&end=${counItems}&profName=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInCatalog: async (counItems, profName) => {
    const response = await fetch(
      baseBeautyUrl + `PullOrderCat.php?start=0&end=${counItems}&prof=${profName}`,
    );
    const data = response.json();
    return data;
  },

  ClientServiceRequests: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderTorgy2.php`, {
      method: 'POST',
    }).then((response) => response.json());
    return response;
  },

  MasterServiceRequests: async (id) => {
    const fd = new FormData();
    fd.append('masterId', id);
    const response = await fetch(baseBeautyUrl + `profil/pullRequestMaster.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response.json();
    return data;
  },

  MyRequests: async (RServiceEnd, RServicesValue, myId) => {
    const data = new FormData();
    data.append('start', RServiceEnd);
    data.append('end', RServicesValue);
    data.append('clientId', myId);

    const response = await fetch(baseBeautyUrl + `PullHistoryOrder.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    console.log(response);
    return response;
  },

  ClientService: async (fd) => {
    const response = await fetch(baseBeautyUrl + `torgy/ApplyOrder.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response;
    return data;
  },

  MasterReqAccept: async (fd) => {
    const response = await fetch(
      `https://bshop.kz/beauty/beautyApi/torgy/beautyOrderApplyMaster.php`,
      {
        method: 'POST',
        body: fd,
      },
    );
    const data = response;
    return data;
  },

  fetchDataMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/profil.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  fetchBlogMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/PullPubImg.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  uploadAvatarUser: async (obj) => {
    const response = await fetch(baseBeautyUrl + `uploadImg.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    const data = response;
    return data;
  },

  // 1 select
  fetchCategoryesMaster: async () => {
    const response = await fetch(baseBeautyUrl + `CatAndOrder/PullCat.php`, {
      method: 'POST',
    });
    const data = response.json();
    return data;
  },
  // select 2
  fetchServicesMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `CatAndOrder/PullOrder.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  fetchServicesInReq3: async (value) => {
    let result;
    await fetch(baseBeautyUrl + `PullOrderCatReq3.php`)
      .then((res) => res.json())
      .then((data) => {
        result = data.data.filter((ids) => {
          return ids.prod_id_add == value;
        });
      });
    return result;
  },

  addCategoryMaster: async (fd) => {
    const response = await fetch(baseBeautyUrl + `profil/prof2.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response.json();
    return data;
  },

  deleteAccount: async (fd) => {
    const response = await fetch(baseBeautyUrl + `profil/deleteProfil.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response.json();
    return data;
  },
  saveEditAccount: async (fd) => {
    const response = await fetch(baseBeautyUrl + `profil/editProfil.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response.json();
    return data;
  },
  login: async (fd) => {
    // const response = await fetch(baseBeautyUrl + `controllers/login.php`, {
    const response = await fetch(baseBeautyUrl + `/profil/logIn.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response.json();
    return data;
  },

  userNotification: async (obj) => {
    const response = await fetch(
      `https://admin.bikli.kz/api/Notifications/appropriateTagLogin.php`,
      {
        method: 'POST',
        body: obj,
      },
    );
    // const data = response.json();
    // return data;
  },

  notificationToAcceptForUser: async (obj) => {
    const response = await fetch(`https://admin.bikli.kz/api/Notifications/pushToClient.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    // return data;
  },

  logout: async (obj) => {
    const response = await fetch(`https://admin.bikli.kz/api/Notifications/logout.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    // return data;
  },
  orderDone: async (obj) => {
    const response = await fetch(baseBeautyUrl + `torgy/completedRequest.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    // return data;
  },

  MyAcceptedRequestMaster: async (masterID) => {
    const data = new FormData();

    data.append('id', masterID);

    const response = await fetch(baseBeautyUrl + `profil/masterProfill.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  ServicesOfMaster: async (RServiceEnd, RServicesValue, masterID) => {
    const data = new FormData();
    data.append('userId', masterID);
    const response = await fetch(baseBeautyUrl + `profil/putOrderMaster.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  Schedule: async (obj) => {
    const response = await fetch(baseBeautyUrl + `works/addDateWorks.php`, {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  getSchedule: async (obj) => {
    const response = await fetch(baseBeautyUrl + `works/pullDate.php`, {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    return response;
  },

  deleteTime: async (obj) => {
    const response = await fetch('https://bshop.kz/beauty/beautyApi/works/deleteTimeWorks.php', {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    return response;
  },

  getPersonalClient: async (obj) => {
    const response = await fetch(baseBeautyUrl + `works/pullEntry.php`, {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    return response;
  },

  acceptedPersonalClient: async (obj) => {
    fetch(`https://admin.bikli.kz/api/Notifications/works/pushAcceptOrRefused.php`, {
      method: 'POST',
      body: obj,
    });
    const response = await fetch(baseBeautyUrl + `works/accept.php`, {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    return response;
  },

  canceledPersonalClient: async (obj) => {
    fetch(`https://admin.bikli.kz/api/Notifications/works/pushAcceptOrRefused.php`, {
      method: 'POST',
      body: obj,
    });
    const response = await fetch(`https://bshop.kz/beauty/beautyApi/works/refused.php`, {
      method: 'POST',
      body: obj,
    }).then((response) => response.json());
    return response;
  },

  doneClient: async (obj) => {
    const response = await fetch(
      'https://bshop.kz/beauty/beautyApi/individual_entry/completed.php',
      {
        method: 'POST',
        body: obj,
      },
    ).then((response) => response.json());
    return response;
  },

  
  entryPersonal: async (obj) => {
    const response = await fetch(
      'https://bshop.kz/beauty/beautyApi/individual_entry/entry_personal.php',
      {
        method: 'POST',
        body: obj,
      },
    ).then((response) => response.json());
    return response;
  },

  getIndividualEntry: async (obj) => {
    const response = await fetch(
      'https://bshop.kz/beauty/beautyApi/individual_entry/get_individual_entry.php',
      {
        method: 'POST',
        body: obj,
      },
    ).then((response) => response.json());
    return response;
  },

  
};

export default beautyApi;
