import React, { useRef, useState, useEffect } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inputmask from 'inputmask';
import star from '../../../src/asseets/star.png';
const Registration_form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputP = useRef();

  const [UserName, setName] = useState('');
  const [salon, setSalon] = useState('');
  const [adress, setAdress] = useState('');
  const [Phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneInput, setphoneInput] = useState(false);
  const [passwordInp, setPasswordInp] = useState('');
  const [agreePasswordInp, setAgreePasswordInp] = useState('');
  const [cities, setCities] = useState([]);
  const [city_id, setCityId] = useState(0);

  // NEW
  const [verificationCode, setVerificationCode] = useState('');
  const [serverVerificationCode, setServerVerificationCode] = useState('');
  const [tab, setTab] = useState(0);
  // ************************

  const urlPath = localStorage.getItem('urlPath');
  const emregex = /\S+@\S+\.\S+/;

  inputP.current && Inputmask({ mask: '+9 (999)-999-99-99' }).mask(inputP.current);

  useEffect(() => {
    getCityList();
  }, []);

  const getCityList = async () => {
    let cities = await fetch('https://beauty.bikli.kz/beautyApi/getListOfCities.php');
    cities = await cities.json();
    setCities(cities);
  };

  const onPhoneInput = () => {
    // inputP.current.value = '';
    setphoneInput(true);
  };

  const wave_error_phone = () => {
    toast('Ошибка! Некорректно введенное поле Телефон', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  const wave_error_name = () => {
    toast('Ошибка! Некорректно введенное поле Имя', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  const wave_error_email = () => {
    toast('Ошибка! Некорректно введенное поле Почты', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  const wave_error_adress = () => {
    toast('Ошибка! Некорректно введенное поле Адрес', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  const wave_success = () =>
    toast('Вы успешно зарегистрировались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  const wave_yandex = () =>
    toast(
      'Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
      },
    );
  const wave_mail_not_success = () =>
    toast('Ошибка! Не удалось отправить письмо на указанную почту', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  const no_agree_pass = () =>
    toast('Пароли не совпадают', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  const wave_call = () =>
    toast(
      'Сейчас Вам позвонит робот, отвечать на звонок не нужно! Запомните последние 4 цифры и введите их в поле для кода.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
        autoClose: false,
      },
    );
  const wave_repeat_not_success = () =>
    toast('Ошибка! Клиент уже зарегистрирован!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  const undef_error = () =>
    toast('Возникла ошибка на стороне сервера. Попробуйте позже', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // NEW **************
  const verifyCode_error = () =>
    toast('Неправильно введен код. Попробуйте позже', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // **************************

  const PhoneCode = async () => {
    if (phoneInput == true) {
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== '_') {
          if (inputP.current.inputmask.undoValue !== '') {
            if (UserName !== '') {
              if (adress !== '') {
                setTab(1);
                wave_call();
                const phonedata = new FormData();
                let formatted_phone = Phone;
                formatted_phone = formatted_phone.replaceAll('-', '');
                formatted_phone = formatted_phone.replaceAll(' ', '');
                formatted_phone = formatted_phone.replaceAll('(', '');
                formatted_phone = formatted_phone.replaceAll(')', '');
                formatted_phone = formatted_phone.replace('+7', '7');
                phonedata.append('phone', Phone);

                fetch(`https://beauty.bikli.kz/beautyApi/flashcall.php`, {
                  method: 'POST',
                  body: phonedata,
                })
                  .then((response) => response.json())
                  .then((res) => setServerVerificationCode(res.code));
                inputP.current.setAttribute('disabled', 'disabled');
              } else {
                wave_error_adress();
              }
            } else {
              wave_error_name();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      wave_error_phone();
    }
  };

  const SendRegForm = async (e) => {
    if (phoneInput == true) {
      if (passwordInp === agreePasswordInp) {
        if (inputP.current.inputmask.undoValue !== undefined) {
          if (inputP.current.inputmask.undoValue[17] !== '_') {
            if (inputP.current.inputmask.undoValue !== '') {
              if (UserName !== '') {
                if (adress !== '') {
                  const regdata = new FormData();

                  regdata.append('FIO', UserName);
                  regdata.append('SalonName', salon);
                  regdata.append('Adress', adress);
                  regdata.append('Phone', Phone);
                  regdata.append('status', 1);
                  regdata.append('password', passwordInp);
                  regdata.append('city_id', city_id);

                  let response = await fetch(
                    `https://bshop.kz/beauty/beautyApi/BRegistration_form3.php`,
                    {
                      method: 'POST',
                      body: regdata,
                    },
                  );
                  let commits = await response.json(); // читаем ответ в формате JSON
                  const PHPresponse = commits[0].response;
                  console.log(PHPresponse);

                  PHPresponse == 'Успешно!'
                    ? wave_success()
                    : PHPresponse == 'Клиент уже зарегистрирован!'
                    ? wave_repeat_not_success()
                    : PHPresponse == 'Ошибка отправки письма'
                    ? wave_success()
                    : undef_error();

                  if (email.indexOf('yandex') + 1) {
                    wave_yandex();
                  }
                } else {
                  wave_error_adress();
                }
              } else {
                wave_error_name();
              }
            } else {
              wave_error_phone();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        no_agree_pass();
      }
    } else {
      wave_error_phone();
    }
  };

  return (
    <>
      <div className="reg_body">
        <div style={{ marginTop: '20px' }} className="reg_wrapper">
          <h1 className="reg_title" style={{ marginTop: '10px' }}>
            Регистрация
          </h1>
          <div style={{ textAlign: 'left' }} className="registration_form">
            <>
              <p style={{ display: 'inline' }}>Имя</p>{' '}
              <img style={{ marginBottom: '8px' }} src={star}></img>
              <Registration_text_field
                name="password"
                onChange={(e) => setName(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder2"
              />
              <p style={{ display: 'inline' }}>Название салона</p>
              <Registration_text_field
                name="password"
                onChange={(e) => setSalon(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder2"
              />
              <p style={{ display: 'inline' }}>Адрес оказания услуги</p>{' '}
              <img style={{ marginBottom: '8px' }} src={star}></img>
              <Registration_text_field
                name="password"
                onChange={(e) => setAdress(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder2"
              />
              <p style={{ display: 'inline' }}>Город</p>{' '}
              <img style={{ marginBottom: '8px' }} src={star}></img>
              <select
                className="reg_placeholder2"
                type="text"
                onChange={(e) => setCityId(e.target.value)}>
                <option value="0">Не указано</option>
                {cities.map((item) => (
                  <option key={item.city_id} value={item.city_id}>
                    {item.city}
                  </option>
                ))}
              </select>
              <p style={{ textAlign: 'center', display: 'inline' }}>Телефон</p>{' '}
              <img style={{ marginBottom: '8px' }} src={star}></img>
              <div className="inputGroup">
                <input
                  ref={inputP}
                  className="reg_placeholder2"
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  onClick={() => {
                    onPhoneInput();
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  display: tab == 0 ? 'block' : 'none',
                }}
                className="inputGroup">
                <button onClick={PhoneCode} className="reg_btn" style={{ marginBottom: 20 }}>
                  Получить код
                </button>
              </div>
              <div style={{ display: tab == 1 ? 'block' : 'none' }}>
                <p style={{ display: 'inline' }}>Введите код</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="passwordCode"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  error="none"
                  placeholder="Код"
                  className="reg_placeholder2"
                  // label={"Код"}
                />
                <p style={{ display: 'inline' }}>Введите пароль</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="password"
                  onChange={(e) => setPasswordInp(e.target.value)}
                  error="none"
                  placeholder="Пароль"
                  className="reg_placeholder2"
                  // label={"Код"}
                />
                <p style={{ display: 'inline' }}>Повторите пароль</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="password"
                  onChange={(e) => setAgreePasswordInp(e.target.value)}
                  error="none"
                  placeholder="Повторите пароль"
                  className="reg_placeholder2"
                  // label={"Код"}
                />

                <div className="inputGroup">
                  <button
                    onClick={(e) => {
                      if (verificationCode == serverVerificationCode.substr(2, 4)) {
                        SendRegForm();
                        console.log('success');
                      } else {
                        verifyCode_error();
                        console.log('error');
                      }
                    }}
                    className="reg_btn"
                    style={{ marginTop: '10px', marginBottom: 20 }}>
                    Продолжить
                  </button>
                </div>
              </div>
              <button
                className="reg_abs"
                style={{
                  marginTop: '0px',
                  textAlign: 'center',
                  marginBottom: 10,
                }}
                onClick={() => history.goBack()}>
                назад
              </button>
            </>

            <div style={{ marginBottom: '130px' }}>
              <img style={{ display: 'inline' }} src={star}></img>
              <p style={{ marginLeft: '10px', display: 'inline' }}>
                {' '}
                поля обязательные к заполнению
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ToastContainer position="top-center" theme="dark" limit={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration_form;
