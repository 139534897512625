/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { confirm } from 'react-confirm-box';
import uniqid from 'uniqid';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import useReactIpLocation from 'react-ip-details';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect,
} from 'react-device-detect';

import beautyApi from '../../api/beautyApi';

import ItemClientRequest from '../beauty/ItemClientReq/ItemClientRequest';
import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';
import AcceptedOrder from './AcceptedOrder';
import {
  onSearchItemAllServRequests,
  onScrollCatalog,
  AddCountItemAllServR,
  onAddAllServR,
  onAddAllServRM,
} from '../../redux/action';

const MasterRequest = ({ ws }) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [cat, setCat] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const websocketAllOrder = useSelector((state) => state.websocketAllOrder);
  const websocketMaster = useSelector((state) => state.websocketMaster);
  const AllServR = useSelector((state) => state.itemsAllServR);
  const ServItemsR = useSelector((state) => state.ServItemsR);
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);

  const time = localStorage.getItem('time');

  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });
  const { RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector(
    (state) => state.onSearchItemAllServRequests,
  );

  const tabs = ['новые заявки', 'принятые заявки'];

  let responseAllServR = [];
  let responseAllServRFiltered = [];
  let client = [];
  let resServ = [];

  const login = JSON.parse(localStorage.getItem('account'));

  if (ipResponse && accountBeautyInfo.id && String(accountBeautyInfo.IMEI).length < 10) {
    const notificationdata = new FormData();
    notificationdata.append('ip', ipResponse.IPv4);
    notificationdata.append('id', accountBeautyInfo.id);
    notificationdata.append('time', time);
    notificationdata.append('osName', osName);
    notificationdata.append('osVersion', osVersion);
    notificationdata.append('mobileVendor', mobileVendor);
    notificationdata.append('mobileModel', mobileModel);
    const NotifAnswer = beautyApi.userNotification(notificationdata);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    getCat();

    return () => {
      localStorage.setItem('app_length', responseAllServRFiltered.length);
      localStorage.setItem('applications', JSON.stringify(responseAllServRFiltered));
      dispatch(onAddAllServR([]));
    };
  }, []);

  useEffect(() => {
    tab === 0 && getServReq(ServItemsR, '', '3', '');
    tab === 1 && getServReqMaster();
  }, [websocketAllOrder, tab]);

  const getCat = async () => {
    const res = await beautyApi.fetchCategoryesMaster();
    setCat(res);
  };

  var arrObjects = [];
  const makeUser = (
    id,
    FIO,
    cat_id,
    orderName,
    orderNameAdd,
    orderName2,
    orderNameAdd2,
    orderName3,
    orderNameAdd3,
    orderName4,
    orderNameAdd4,
    orderName5,
    orderNameAdd5,
    date,
    time,
    price,
    comm,
    clientId,
    img,
    read,
  ) => {
    return {
      id: id,
      FIO: FIO,
      cat_id: cat_id,
      orderName: orderName,
      orderNameAdd: orderNameAdd,
      orderName2: orderName2,
      orderNameAdd2: orderNameAdd2,
      orderName3: orderName3,
      orderNameAdd3: orderNameAdd3,
      orderName4: orderName4,
      orderNameAdd4: orderNameAdd4,
      orderName5: orderName5,
      orderNameAdd5: orderNameAdd5,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      img: img,
      read: read,
      // ...другие свойства
    };
  };

  var arrObjectsAccept = [];
  const makeUserAccept = (
    id,
    FIO,
    cat_id,
    orderName,
    date,
    time,
    price,
    comm,
    clientId,
    status,
    phoneClient,
    Adress,
    SalonName,
    img,
  ) => {
    return {
      id: id,
      FIO: FIO,
      cat_id: cat_id,
      orderName: orderName,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      status: status,
      phoneClient: phoneClient,
      Adress: Adress,
      SalonName: SalonName,
      img: img,
      // ...другие свойства
    };
  };

  const filterResponseAllServR = async (RServiceEnd, RServicesValue) => {
    const regdata = new FormData();
    responseAllServR = [];
    regdata.append('phone', login.login);
    regdata.append('pass', login.pass);
    regdata.append('status', 1);

    await beautyApi.login(regdata).then(async (res) => {
      resServ = await res[1];
    });
    let arr = [];

    if (resServ.length > 0) {
      if (websocketAllOrder.length === 0) {
        arr = await beautyApi.ClientServiceRequests(RServiceEnd, RServicesValue);

        client = arr;
      } else {
        client = websocketAllOrder;
        arr = websocketAllOrder;
      }
      resServ.forEach((itemServ) => {
        responseAllServR = [
          ...responseAllServR,
          ...arr.filter((item) => item?.data?.cat_id === itemServ.cat_id),
        ];
      });

      responseAllServRFiltered = [];
      responseAllServR = responseAllServR.sort((a, b) => a.data.id - b.data.id);

      responseAllServR.map(async (item, key) => {
        let oneClient = [];
        client.forEach((itemC) => {
          if (itemC?.client?.id === item.data.clientId) {
            oneClient = itemC?.client;
          }
        });
        responseAllServRFiltered.push(
          { ...item },
          {
            client: { ...oneClient },
          },
        );
      });
    } else {
      responseAllServRFiltered = await beautyApi.ClientServiceRequests(RServiceEnd, RServicesValue);
    }

    await filterRead(responseAllServRFiltered);
  };

  const filterRead = async (responseAllServRFiltered) => {
    if (
      !localStorage.getItem('app_length') ||
      localStorage.getItem('app_length') > responseAllServRFiltered.length
    ) {
      localStorage.setItem('app_length', responseAllServRFiltered.length);
    }
  };

  const getServReq = async () => {
    setisLoading(true);
    await filterResponseAllServR();

    for (let i = 0; i < responseAllServRFiltered.length; i = i + 2) {
      let user = makeUser(
        responseAllServRFiltered[i].data['id'],
        responseAllServRFiltered[i + 1].client['FIO'],
        responseAllServRFiltered[i].data['cat_id'],
        responseAllServRFiltered[i].data['orderName'],
        responseAllServRFiltered[i].data['orderNameAdd'],
        responseAllServRFiltered[i].data['orderName2'],
        responseAllServRFiltered[i].data['orderNameAdd2'],
        responseAllServRFiltered[i].data['orderName3'],
        responseAllServRFiltered[i].data['orderNameAdd3'],
        responseAllServRFiltered[i].data['orderName4'],
        responseAllServRFiltered[i].data['orderNameAdd4'],
        responseAllServRFiltered[i].data['orderName5'],
        responseAllServRFiltered[i].data['orderNameAdd5'],
        responseAllServRFiltered[i].data['date'],
        responseAllServRFiltered[i].data['time'],
        responseAllServRFiltered[i].data['price'],
        responseAllServRFiltered[i].data['comm'],
        responseAllServRFiltered[i + 1].client['id'],
        responseAllServRFiltered[i + 1].client['img'],
        responseAllServRFiltered[i].read,
      );
      arrObjects[i] = user;
    }
    dispatch(onAddAllServR(arrObjects.reverse()));
    setisLoading(false);

    if (arrObjects.length === 0) {
      no_applications(customRender);
    }
  };

  const getServReqMaster = async () => {
    if (websocketMaster.length <= 0) {
      responseAllServR = await beautyApi.MasterServiceRequests(accountBeautyInfo.id);
    } else {
      responseAllServR = websocketMaster;
    }

    for (let i = 0; i < responseAllServR.length; i = i + 2) {
      let user = makeUserAccept(
        responseAllServR[i].data['id'],
        responseAllServR[i + 1].client['FIO'],
        responseAllServR[i].data['cat_id'],
        responseAllServR[i].data['orderName'],
        responseAllServR[i].data['date'],
        responseAllServR[i].data['time'],
        responseAllServR[i].data['price'],
        responseAllServR[i].data['comm'],
        responseAllServR[i + 1].client['id'],
        responseAllServR[i].data['status'],
        responseAllServR[i + 1].client['Phone'],
        responseAllServR[i + 1].client['Adress'],
        responseAllServR[i + 1].client['SalonName'],
        responseAllServR[i + 1].client['img'],
      );
      arrObjectsAccept[i] = user;
    }
    dispatch(onAddAllServRM(arrObjectsAccept.reverse()));
  };

  const no_applications = async (options) => {
    const result = await confirm('Сейчас заявок нету, вернитесь позже!', options);
    if (result) {
      return;
    }
  };

  const customRender = {
    render: (message, onConfirm) => {
      return (
        <div className={s.wrapModalConfirm}>
          <div className={s.modalConfirm}>
            <div className={s.modalConfirmMessage}> {message} </div>
            <div className={s.modalConfirmButton}>
              <button onClick={onConfirm}> Хорошо</button>
            </div>
          </div>
        </div>
      );
    },
  };

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemAllServR(ServItemsR + 2));
    } else {
      dispatch(
        onSearchItemAllServRequests({
          RServicesValue: RServicesValue,
          RServiceEnd: RServiceEnd + 2,
        }),
      );
    }
  };

  return (
    <div className={s.wapper_request}>
      <div className={s.inner_request}>
        {tabs.map((item, key) => (
          <div key={key}>
            <div onClick={() => setTab(key)}>
              {item}
              <div className={key === tab ? s.inner_request_active : null}></div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {tab === 0 && (
          <div
            className={s.all_items}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            {AllServR.length > 0 ? (
              <InfiniteScroll
                style={{}}
                dataLength={AllServR.length}
                next={fetchNewItems}
                hasMore={true}
                loader={<div style={{ width: '50%' }}></div>}
                sscrollableTarget="scrollableDiv">
                {AllServR.map((item) => (
                  <ItemClientRequest key={uniqid()} ws={ws} cat={cat} {...item} />
                ))}
              </InfiniteScroll>
            ) : (
              Array.from(Array(8), (_) => <ServicesLoadingBlock key={uniqid()} />)
            )}
          </div>
        )}
        {tab === 1 && <AcceptedOrder statusView={0} statusText={'принято'} />}
      </div>

      <div className={s.footer}></div>
    </div>
  );
};

export default MasterRequest;
