const websocketAllOrder = (value) => {
  return {
    type: 'TO_WEBSOCKET_ALL',
    payload: value,
  };
};

const websocketMaster = (value) => {
  return {
    type: 'TO_WEBSOCKET_MASTER',
    payload: value,
  };
};
const chooseTypeSection = (typeSection) => {
  return {
    type: 'CHOOSE_TYPE_SECTION',
    typeSection,
  };
};

const setDate = (json, item) => {
  return {
    type: 'SET_DATE',
    payload: json,
    item,
  };
};

const setItem = (json, item) => {
  return {
    type: 'SET_ITEM',
    payload: json,
    item,
  };
};

const setItemCategory = (category) => {
  return {
    type: 'SET_ITEM_CATEGORY',
    payload: category,
  };
};

const filterCategory = (id) => {
  return {
    type: 'FILTER_CATEGORY',
    payload: id,
  };
};

export const setCalendarReqDay = (value) => {
  return {
    type: 'TO_SET_CalendarReqDay',
    payload: value,
  };
};

const addItemBasket = (item) => {
  return {
    type: 'ADD_ITEM_BASKET',
    payload: item,
  };
};

const countItem = (id, operator, item) => {
  return {
    type: 'COUNT_ITEM',
    payload: id,
    operator,
    item,
  };
};

const onEmptyBasket = () => {
  return {
    type: 'ON_EMPTY_BASKET',
  };
};

const errorStatus = () => {
  return {
    type: 'ERROR',
  };
};

const getUserData = (data) => {
  return {
    type: 'GET_USER_DATA',
    payload: data,
  };
};

const delAdressDeliveryBasket = (id) => {
  return {
    type: 'DEL_ADRESS_DELIVERY_BASKET',
    payload: id,
  };
};

const logOut = () => {
  return {
    type: 'LOG_OUT',
  };
};

const bodyOverflowToCard = (state) => {
  return {
    type: 'BODY_OVERFLOW_TO_CARD',
    state,
  };
};
const addBasketBshop = (obj) => {
  return {
    type: 'ADD_ITEM_BASKET_BSHOP',
    payload: obj,
  };
};

const removeBasketBshop = (obj) => {
  return {
    type: 'REMOVE_ITEM_BASKET_BSHOP',
    payload: obj,
  };
};

const onSearchItemBshop = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_BSHOP',
    payload: value,
  };
};

const onSearchItemPubl = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_PUBL',
    payload: value,
  };
};

const onSearchItemAllServ = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_ALL_SERV',
    payload: value,
  };
};

const onSearchItemAllServRequests = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_ALL_SERV_REQUESTS',
    payload: value,
  };
};

const onShowMasters = (value) => {
  return {
    type: 'ON_SHOW_MASTERS',
    payload: value,
  };
};

const onShopGo = (value) => {
  return {
    type: 'TO_SHOP',
    payload: value,
  };
};
const onItemGo = (value) => {
  return {
    type: 'TO_ITEM',
    payload: value,
  };
};

const onScrollCatalog = (value) => {
  return {
    type: 'TO_SCROLL_CATALOG',
    payload: value,
  };
};

const onMastersCategory = (value) => {
  return {
    type: 'TO_MASTERS_CATEGORY',
    payload: value,
  };
};

const onAddItemBshop = (value) => {
  return {
    type: 'TO_ADD_ITEM_BSHOP',
    payload: value,
  };
};

const onAddPubl = (value) => {
  return {
    type: 'TO_ADD_ITEM_PUBL',
    payload: value,
  };
};

const onAddAllServ = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV',
    payload: value,
  };
};

const onAddAllServR = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV_R',
    payload: value,
  };
};
const onAddAllServRM = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV_R_M',
    payload: value,
  };
};

const AddCountItemBshop = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_BSHOP',
    payload: value,
  };
};

const AddCountItemPubl = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_PUBL',
    payload: value,
  };
};

const AddCountItemAllServ = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_ALL_SERV',
    payload: value,
  };
};

const AddCountItemAllServR = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_ALL_SERV_R',
    payload: value,
  };
};

const ServBorderType = (value) => {
  return {
    type: 'S_BORDER_TYPE',
    payload: value,
  };
};

const MasterBorderType = (value) => {
  return {
    type: 'M_BORDER_TYPE',
    payload: value,
  };
};

const ServBorderImg = (value) => {
  return {
    type: 'S_BORDER_IMG',
    payload: value,
  };
};

const MasterBorderImg = (value) => {
  return {
    type: 'M_BORDER_IMG',
    payload: value,
  };
};

const DisplayType = (value) => {
  return {
    type: 'DISPLAY_FLEX',
    payload: value,
  };
};

const DopDisplayType = (value) => {
  return {
    type: 'DOP_DISPLAY_FLEX',
    payload: value,
  };
};

const accountBeauty = (value) => {
  return {
    type: 'TO_ON_ACCOUNT_BEAUTY',
    payload: value,
  };
};
const accountMasterServices = (value) => {
  return {
    type: 'TO_SERVICES_MASTER',
    payload: value,
  };
};

const onServMastersMenu = (value) => {
  return {
    type: 'TO_ON_SERVMASTERS_MENU',
    payload: value,
  };
};

const onAddAllMasterServR = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_MASTER_SERV_R',
    payload: value,
  };
};

const getDate = (value) => {
  return {
    type: 'GET_DATE',
    payload: value,
  };
};

const headerMargin = (value) => {
  console.log('r', value);
  return {
    type: 'HEADER_MARGIN',
    payload: value,
  };
};

export {
  getDate,
  onAddAllMasterServR,
  onServMastersMenu,
  websocketAllOrder,
  websocketMaster,
  setDate,
  setItem,
  errorStatus,
  setItemCategory,
  filterCategory,
  addItemBasket,
  countItem,
  onEmptyBasket,
  getUserData,
  delAdressDeliveryBasket,
  logOut,
  bodyOverflowToCard,
  chooseTypeSection,
  addBasketBshop,
  removeBasketBshop,
  onSearchItemBshop,
  onSearchItemPubl,
  onSearchItemAllServ,
  onShopGo,
  onItemGo,
  onScrollCatalog,
  onAddItemBshop,
  onAddPubl,
  AddCountItemBshop,
  AddCountItemPubl,
  onMastersCategory,
  onShowMasters,
  ServBorderType,
  MasterBorderType,
  AddCountItemAllServ,
  onAddAllServ,
  accountBeauty,
  DisplayType,
  DopDisplayType,
  onSearchItemAllServRequests,
  AddCountItemAllServR,
  onAddAllServR,
  onAddAllServRM,
  ServBorderImg,
  MasterBorderImg,
  accountMasterServices,
  headerMargin,
};
