import React, { useRef, useState } from 'react';
import s from './req.module.css';
import uniqid from 'uniqid';
import Popup from 'reactjs-popup';
import AcceptModul from './AcceptModul';
import './animation.css';
import imgDone from './Vector (2).png';
const ItemClientRequest = ({
  FIO,
  client,
  date,
  id,
  orderId,
  orderName,
  orderNameAdd,
  orderName2,
  orderNameAdd2,
  orderName3,
  orderNameAdd3,
  orderName4,
  orderNameAdd4,
  orderName5,
  orderNameAdd5,
  phome,
  price,
  status,
  time,
  comm,
  img,
  clientId,
  cat_id,
  read,
  SalonName,
  phoneClient,
  Adress,
  ws,
  cat,
  statusView,
  statusText,
  services,
  dop_services,
}) => {
  const urlPath = window.location.pathname;
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const day = new Date(date);
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const orderOne = useRef(null);
  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  };

  const content = () => {
    return (
      <>
        <div>
          <div className={s.item__bshop_image} onClick={() => ReqAccept()}>
            <img
              src={
                img && img !== ''
                  ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                  : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
              }
              alt="logo"
            />
          </div>
          <div className={s.item_client_req_info_nameClient}>{FIO || client}</div>
        </div>

        <div className={s.item_client_req_info}>
          <div className={s.app_title_wrapp}>
            {cat[cat_id - 1]?.prof && (
              <div className={s.app_title} style={{ fontWeight: 'bold' }}>
                {cat[cat_id - 1]?.prof}:
              </div>
            )}
            <div>
              {services && dop_services && (
                <div className={s.app_subtitle} style={{ textAlign: 'start' }}>
                  {services} : {dop_services}
                </div>
              )}
              {orderName && orderName !== '-' && orderName !== '' && (
                <div className={s.app_subtitle}>{orderName};</div>
              )}
              {orderNameAdd && orderNameAdd !== '-' && orderNameAdd !== '' && (
                <div className={s.app_subtitle}>{orderNameAdd};</div>
              )}
              {orderName2 && orderName2 !== '-' && orderName2 !== '' && (
                <div className={s.app_subtitle}>{orderName2};</div>
              )}
              {orderNameAdd2 && orderNameAdd2 !== '-' && orderNameAdd2 !== '' && (
                <div className={s.app_subtitle}>{orderNameAdd2};</div>
              )}
              {orderName3 && orderName3 !== '-' && orderName3 !== '' && (
                <div className={s.app_subtitle}>{orderName3};</div>
              )}
              {orderNameAdd3 && orderNameAdd3 !== '-' && orderNameAdd3 !== '' && (
                <div className={s.app_subtitle}>{orderNameAdd3};</div>
              )}
              {orderName4 && orderName4 !== '-' && orderName4 !== '' && (
                <div className={s.app_subtitle}>{orderName4};</div>
              )}
              {orderNameAdd4 && orderNameAdd4 !== '-' && orderNameAdd4 !== '' && (
                <div className={s.app_subtitle}>{orderNameAdd4};</div>
              )}
              {orderName5 && orderName5 !== '-' && orderName5 !== '' && (
                <div className={s.app_subtitle}>{orderName5};</div>
              )}
              {orderNameAdd5 && orderNameAdd5 !== '-' && orderNameAdd5 !== '' && (
                <div className={s.app_subtitle}>{orderNameAdd5}.</div>
              )}
            </div>
          </div>
          <div className={s.app_wrap_order_info} style={{ textAlign: 'left' }}>
            <div className={s.app_info_order}>
              <div>Дата:</div>
              <div>Время:</div>
              <div>Цена:</div>
            </div>
            <div className={s.app_info_order}>
              <div>{day.toLocaleDateString('ru-ru', options)}</div>
              <div>{time ? time : '-'}</div>
              <div>
                <span style={{ fontWeight: 'bold' }}> {Number(price).toLocaleString('ru')}</span>{' '}
                тнг
              </div>
            </div>
            <Popup
              trigger={
                <div className={s.dropdown_comm}>
                  {comm?.length > 0 ? (
                    <>
                      <svg
                        width="46"
                        height="44"
                        viewBox="0 0 46 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1267_11675)">
                          <path
                            d="M15.3522 38.5276C14.8486 38.9042 14.2194 39.0652 13.6001 38.9759C12.9807 38.8866 12.4206 38.5541 12.0404 38.05C11.7357 37.6427 11.5704 37.1454 11.5697 36.634V31.4211H10.1504C6.75348 31.4211 4 28.6635 4 25.2627V9.15847C3.99764 5.75763 6.75112 3 10.148 3H35.6958C39.0927 3 41.8462 5.75763 41.8462 9.15847V25.2627C41.8462 28.6659 39.0927 31.4211 35.6958 31.4211H24.838L15.3498 38.5276H15.3522Z"
                            fill="url(#paint0_linear_1267_11675)"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1267_11675"
                            x="0"
                            y="0"
                            width="45.8462"
                            height="44"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1267_11675"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1267_11675"
                              result="shape"
                            />
                          </filter>
                          <linearGradient
                            id="paint0_linear_1267_11675"
                            x1="41.8757"
                            y1="3"
                            x2="1.95771"
                            y2="5.46626"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="#D432D8" />
                            <stop offset="0.515625" stopColor="#FF608D" />
                            <stop offset="1" stopColor="#F4A582" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <svg
                        style={{ position: 'absolute', top: '8', left: '20' }}
                        width="5"
                        height="16"
                        viewBox="0 0 5 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.64 10.764L0.606 1.04V0.599999H4.522V1.04L3.488 10.764H1.64ZM0.782 16V12.48H4.346V16H0.782Z"
                          fill="#F3F3F3"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="46"
                        height="44"
                        viewBox="0 0 46 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1269_11708)">
                          <path
                            d="M15.3522 38.5276C14.8486 38.9042 14.2194 39.0652 13.6001 38.9759C12.9807 38.8866 12.4206 38.5541 12.0404 38.05C11.7357 37.6427 11.5704 37.1454 11.5697 36.634V31.4211H10.1504C6.75348 31.4211 4 28.6635 4 25.2627V9.15847C3.99764 5.75763 6.75112 3 10.148 3H35.6958C39.0927 3 41.8462 5.75763 41.8462 9.15847V25.2627C41.8462 28.6659 39.0927 31.4211 35.6958 31.4211H24.838L15.3498 38.5276H15.3522Z"
                            fill="#D9D9D9"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1269_11708"
                            x="0"
                            y="0"
                            width="45.8462"
                            height="44"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1269_11708"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1269_11708"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>

                      <svg
                        style={{ position: 'absolute', top: '8', left: '20' }}
                        width="5"
                        height="16"
                        viewBox="0 0 5 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.64 10.764L0.606 1.04V0.599999H4.522V1.04L3.488 10.764H1.64ZM0.782 16V12.48H4.346V16H0.782Z"
                          fill="#F3F3F3"
                        />
                      </svg>
                    </>
                  )}
                </div>
              }
              modal
              nested>
              <div className={s.modal}>
                <div className={s.header}> Комментарий </div>
                <div className={s.content}>
                  <p>{comm}</p>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </>
    );
  };

  // статусы 0-в ожидании, 1 - принят,  , 2 - отказ, 4-активный, 3 - завершен

  if (urlPath === '/beauty/add_day') {
    return (
      <div className={s.item__bshop2} key={uniqid()}>
        {content()}
      </div>
    );
  }

  if (!statusText && urlPath === '/beauty/requests' && !statusView) {
    return (
      <div>
        <div>
          <div className={s.item__bshop2} key={uniqid()}>
            {content()}
          </div>

          {status <= '3' ? null : (
            <AcceptModul
              orderName={orderName}
              orderNameAdd={orderNameAdd}
              orderName2={orderName2}
              orderNameAdd2={orderNameAdd2}
              orderName3={orderName3}
              orderNameAdd3={orderNameAdd3}
              orderName4={orderName4}
              orderNameAdd4={orderNameAdd4}
              orderName5={orderName5}
              orderNameAdd5={orderNameAdd5}
              date={date}
              id={id}
              phome={phome}
              price={price}
              time={time}
              clientId={clientId}
              status={status}
              urlPath={urlPath}
              ws={ws}
              img={img}
              clientName={FIO}
            />
          )}
          {!statusView && status === '1' ? (
            <AcceptModul
              orderName={orderName}
              orderNameAdd={orderNameAdd}
              orderName2={orderName2}
              orderNameAdd2={orderNameAdd2}
              orderName3={orderName3}
              orderNameAdd3={orderNameAdd3}
              orderName4={orderName4}
              orderNameAdd4={orderNameAdd4}
              orderName5={orderName5}
              orderNameAdd5={orderNameAdd5}
              date={date}
              id={id}
              phome={phome}
              price={price}
              time={time}
              clientId={clientId}
              status={status}
              urlPath={urlPath}
              SalonName={SalonName}
              phoneClient={phoneClient}
              addressSalone={Adress}
              clientName={FIO}
              img={img}
            />
          ) : null}
        </div>
        {statusText && (
          <div
            className={statusText === 'выполненно' ? s.order_done_status1 : s.order_done_status3}>
            {statusText}
            {statusText === 'выполненно' && (
              <img style={{ marginLeft: 5 }} src={imgDone} alt="done" />
            )}
            {statusText === 'отказано' && (
              <svg
                style={{ marginLeft: 5 }}
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.60658 0.535534L0.535511 7.6066M0.535511 0.535534L7.60658 7.6066"
                  stroke="#E50000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  } else if (
    urlPath === '/beauty/requests' &&
    statusText === 'принято' &&
    statusView === 0 &&
    status === '0'
  ) {
    return (
      <div>
        <div>
          <div
            className={s.item__bshop2}
            key={uniqid()}
            style={statusView === 0 && statusText === 'принято' ? { marginBottom: '0px' } : null}>
            {content()}
          </div>
        </div>
        {statusText && (
          <div className={statusText === 'принято' ? s.order_done_status0 : null}>{statusText}</div>
        )}
      </div>
    );
  } else if (
    urlPath === '/beauty/acceptncanceled' &&
    statusText === 'выполняется' &&
    statusView === 4 &&
    status === '4'
  ) {
    return (
      <div ref={orderOne}>
        <div>
          <div className={s.item__bshop2} key={uniqid()}>
            {content()}
          </div>

          {statusView === 4 && (
            <AcceptModul
              orderName={orderName}
              orderNameAdd={orderNameAdd}
              orderName2={orderName2}
              orderNameAdd2={orderNameAdd2}
              orderName3={orderName3}
              orderNameAdd3={orderNameAdd3}
              orderName4={orderName4}
              orderNameAdd4={orderNameAdd4}
              orderName5={orderName5}
              orderNameAdd5={orderNameAdd5}
              date={date}
              id={id}
              phome={phome}
              price={price}
              time={time}
              clientId={clientId}
              status={status}
              urlPath={urlPath}
              ws={ws}
              img={img}
              orderId={orderId}
              clientName={FIO}
              statusView={statusView}
              orderOne={orderOne}
            />
          )}
        </div>
      </div>
    );
  } else if (
    urlPath === '/beauty/acceptncanceled' &&
    statusText === 'выполненно' &&
    statusView === 3 &&
    status === '3'
  ) {
    return (
      <div>
        <div>
          <div className={s.topOrderDate} style={{ opacity: 0.7 }}>
            <div>{day.toLocaleDateString('ru-ru', options)}</div>
          </div>
          <div className={s.item__bshop2} key={uniqid()} style={{ opacity: 0.7, marginBottom: 0 }}>
            {content()}
          </div>
        </div>
        {statusText && (
          <div className={s.order_done_status1}>
            {statusText}
            <img style={{ marginLeft: 5 }} src={imgDone} alt="done" />
          </div>
        )}
      </div>
    );
  } else if (
    urlPath === '/beauty/acceptncanceled' &&
    statusText === 'отказано' &&
    statusView === 2 &&
    status === '2'
  ) {
    return (
      <div>
        <div>
          <div className={s.topOrderDate} style={{ opacity: 0.7 }}>
            <div>{day.toLocaleDateString('ru-ru', options)}</div>
          </div>
          <div className={s.item__bshop2} key={uniqid()} style={{ opacity: 0.7, marginBottom: 0 }}>
            {content()}
          </div>
        </div>
        {statusText && (
          <div className={s.order_done_status2}>
            {statusText}
            <svg
              style={{ marginLeft: 5 }}
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.60658 0.535534L0.535511 7.6066M0.535511 0.535534L7.60658 7.6066"
                stroke="#E50000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default ItemClientRequest;
