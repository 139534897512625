import React, { useRef, useEffect, useState } from 'react';
import beautyApi from '../../../api/beautyApi';
import ServiceOfMaster from '../../../companents/beauty/ItemClientReq/ServiceOfMaster';
import s from './ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { onAddAllMasterServR } from '../../../redux/action';
import uniqid from 'uniqid';

const ServicesOfMaster = (props) => {
  const dispatch = useDispatch();

  const AllServR = useSelector((state) => state.itemsAllMasterServR);
  const ServItemsR = useSelector((state) => state.ServItemsR);
  const beenScroll = useSelector((state) => state.scrollCatalog);
  const myId = useSelector((state) => state.accountBeauty.id);
  const masterAbout = useSelector((state) => state.masterAbout);

  const [data, setData] = useState(null);

  const urlPath = window.location.pathname;

  const ws = useRef(null);
  useEffect(() => {}, []);

  useEffect(() => {
    getServReq(ServItemsR, '', myId);
  }, [data]);

  var arrObjects = [];
  const makeUser = (
    cat_id,
    id,
    cat_name,
    masterId,
    serives_add_id_names,
    services_add_id,
    services_id,
    services_name,
    priceServices,
    servicesId_add,
    servicesId_add_name,
  ) => {
    return {
      cat_id: cat_id,
      id: id,
      cat_name: cat_name,
      masterId: masterId,
      serives_add_id_names: serives_add_id_names,
      services_add_id: services_add_id,
      services_id: services_id,
      services_name: services_name,
      priceServices: priceServices,
      // ...другие свойства
    };
  };

  const getServReq = async (RServiceEnd, RServicesValue, myId) => {
    const responseAllServR = await beautyApi.ServicesOfMaster(RServiceEnd, RServicesValue, myId);

    for (let i = 0; i < responseAllServR.length; i++) {
      let user = makeUser(
        responseAllServR[i]['cat_id'],
        responseAllServR[i]['id'],
        responseAllServR[i]['cat_name'],
        responseAllServR[i]['masterId'],
        responseAllServR[i]['serives_add_id_names'],
        responseAllServR[i]['services_add_id'],
        responseAllServR[i]['services_id'],
        responseAllServR[i]['services_name'],
        responseAllServR[i]['priceServices'],
      );

      arrObjects[i] = user;
    }
    dispatch(onAddAllMasterServR(arrObjects.reverse()));
  };

  useEffect(() => {
    if (AllServR.length === 0) {
      //console.log('Первый ');
      getServReq(ServItemsR, '', myId);
    }
  }, []);

  // console.log(AllServR)

  return (
    <>
      <div
        className={s.all_items}
        id="scrollableDiv"
        style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
        {AllServR.length > 0 ? (
          <InfiniteScroll
            style={{}}
            dataLength={AllServR.length}
            hasMore={true}
            loader={
              <div style={{ width: '50%' }}>
                {/* {Array.from(Array(4), (_, i) => (
                              <LoadingBlock key={uniqid()} />
                            ))} */}
              </div>
            }
            sscrollableTarget="scrollableDiv">
            {AllServR.map((item) => (
              <ServiceOfMaster key={uniqid()} {...item} />
            ))}
          </InfiniteScroll>
        ) : (
          <>
            <p className={s.text} style={{ marginBottom: '20px' }}>
              Вы пока что не добавили ни одной услуги. Сделать это можно в настройках аккаунта
            </p>
          </>
          // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
        )}
      </div>
      <div className={s.footer}></div>
    </>
  );
};

export default ServicesOfMaster;
