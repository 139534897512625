import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import s from "../pageMaster.module.css";
import PaymentOrder from "../../../companents/beauty/Licenses/PaymentOrder";
import Button from "../../../companents/UI/Button";

const ProfileMasterAddBalance = () => {
  const accountBeautyBalance = useSelector(
    (state) => state.accountBeauty.balance
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    checkAgreeStatus();
  }, []);

  const tabs = ["Текущий баланс", "История операций"];
  const [tab, setTab] = useState(0);
  const [agreeStatus, setAgreeStatus] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [confPolitic, setConfPolitic] = useState(false);
  const [isAgree, setIsAgree] = useState();
  const [amount, setAmount] = useState(0);

  const checkAgreeStatus = () => {
    let formdata = new FormData();
    formdata.append("id", localStorage.getItem("id_master"));

    fetch(`https://beauty.bikli.kz/beautyApi/checkAgreementStatus.php`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAgree(res.agreementStatus);
        console.log(res.agreementStatus);
      });
  };

  useEffect(() => {}, []);

  const userAgreed = async () => {
    setIsAgree(1);
    let formdata = new FormData();
    formdata.append("id", localStorage.getItem("id_master"));

    let response = await fetch(
      `https://beauty.bikli.kz/beautyApi//setAgreementStatus.php`,
      {
        method: "POST",
        body: formdata,

      }
    );
  };


  const sendPaymentData = async () => {
    const data = JSON.stringify({
      amount: amount,
      description: "Пополнение баланса из личного кабинета",
      id_master: localStorage.getItem("id_master"),
    });
    let response_id = await fetch("https://beauty.bikli.kz/beautyApi/paymentMethod/createOrder.php",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        return response.orders[0].id;
      });

    console.log("ID операции платежа :", response_id);
    window.open(`https://checkout.paymtech.kz/pay/${response_id}`, "_blank");
  };

  return (
    <>
      <div className={s.titleBalance}>Баланс</div>
      <div className={s.inner_request}>
        {tabs.map((item, key) => (
          <div key={key}>
            {/* <div onClick={() => setTab(key)}> */}
            <div>
              <div className={s.menu_item_tab}> {item}</div>
              <div
                className={key === tab ? s.inner_request_active : null}
              ></div>
            </div>
          </div>
        ))}
      </div>

      <div className={s.title_balance_info} style={{ marginBottom: "20px" }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.67881 6.94652C6.72117 6.93051 6.76648 6.92255 6.81212 6.9231H17.1945C17.2398 6.92276 17.2847 6.9309 17.3265 6.94706C17.3684 6.96322 17.4065 6.98707 17.4385 7.01723C17.4705 7.04738 17.4958 7.08324 17.513 7.1227C17.5302 7.16217 17.5388 7.20446 17.5384 7.2471V8.67979C17.539 8.72279 17.5306 8.76547 17.5136 8.80538C17.4966 8.84529 17.4714 8.88164 17.4394 8.91234C17.4074 8.94303 17.3693 8.96747 17.3273 8.98424C17.2853 9.00101 17.2401 9.00978 17.1945 9.01005H6.81212C6.61938 9.01005 6.46154 8.86136 6.46154 8.67979V7.2471C6.46183 7.2041 6.47114 7.16158 6.48894 7.12199C6.50675 7.08239 6.53269 7.0465 6.56527 7.01639C6.59786 6.98628 6.63644 6.96253 6.67881 6.94652ZM6.56639 10.6696C6.63198 10.609 6.72024 10.5751 6.81212 10.5753H17.1945C17.2857 10.5754 17.3731 10.6096 17.4375 10.6703C17.502 10.731 17.5383 10.8134 17.5384 10.8993V12.332C17.539 12.375 17.5306 12.4176 17.5136 12.4575C17.4966 12.4975 17.4714 12.5338 17.4394 12.5645C17.4074 12.5952 17.3693 12.6196 17.3273 12.6364C17.2853 12.6532 17.2401 12.6619 17.1945 12.6622H13.1077V18.5991C13.108 18.6417 13.0994 18.684 13.0822 18.7235C13.0651 18.7629 13.0398 18.7988 13.0078 18.8289C12.9757 18.8591 12.9377 18.883 12.8958 18.8991C12.8539 18.9153 12.809 18.9234 12.7637 18.9231H11.2362C11.0441 18.9231 10.8923 18.7801 10.8923 18.5991V12.6622H6.81212C6.61938 12.6622 6.46154 12.5135 6.46154 12.332V10.8993C6.46314 10.8127 6.5008 10.7302 6.56639 10.6696Z"
            fill="url(#paint0_linear_1510_10471)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1510_10471"
              x1="24.0187"
              y1="-1.09808e-06"
              x2="-1.30427"
              y2="1.48822"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D432D8" />
              <stop offset="0.515625" stopColor="#FF608D" />
              <stop offset="1" stopColor="#F4A582" />
            </linearGradient>
          </defs>
        </svg>
        <div>{Number(accountBeautyBalance).toLocaleString("ru")}</div>
      </div>

      {isAgree == 0 ? (
        <>
          <a
            onClick={(e) => {
              e.preventDefault();
              setConfPolitic(confPolitic ? false : true);
            }}
            href=""
            style={{ marginBottom: "5px", marginLeft: "10%" }}
          >
            Политика конфиденциальности
          </a>

          <div
            className="terms"
            style={{
              margin: "15px 10% 15px 10%",
              fontSize: "12px",
              height: confPolitic == true ? "200px" : "0px",
              boxSizing: "border-box",
              overflow: "scroll",
              transition: "1s",
            }}
            onScroll={(e) => {
              if (
                e.target.scrollHeight - e.target.scrollTop ==
                e.target.clientHeight
              ) {
                setAgreeStatus(true);
              }
            }}
          >
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
            </h3>
            <p>
              Настоящая Политика конфиденциальности данных (далее – Политика
              конфиденциальности) действует в отношении данных, которые АО «Банк
              ЦентрКредит» (БИН 980 640 000 093, г. Алматы, пр. Аль-Фараби 38)
              может получить с использованием веб-сайта, расположенного в сети
              Интернет на доменном имени: https://admin.bikli.kz/. Общество
              обрабатывает данные пользователей Сайта исключительно в рамках
              требований Законодательства Республики Казахстан, в том числе
              Закона Республики Казахстан от 21 мая 2013 года № 94-V «О
              персональных данных и их защите», а также международных договоров,
              ратифицированных Республики Казахстан. Политика конфиденциальности
              (далее — Политика) разработана в соответствии с их нормами.
              <br />
              Использование Пользователем Сайта означает согласие с Политикой
              конфиденциальности и условиями обработки данных. В случае
              несогласия с условиями Политики конфиденциальности Пользователь
              должен прекратить использование Сайта.
              <br />
              Настоящая Политика предназначена для обеспечения надлежащей защиты
              информации о Пользователях, в том числе их персональных данных, от
              несанкционированного доступа и разглашения.
              <br />
              Политика, а также другая официальная документация Сайта и
              действующее законодательство Республики Казахстан регулируют
              отношения, связанные со сбором, хранением, распространением и
              защитой информации о пользователях Сайта. В случае наличия
              противоречий между настоящей Политикой и иными официальными
              документами Сайта применению подлежит настоящая Политика.
              <br />
              Пользователи должны знать, что при переходе на некоторые ссылки,
              размещённые на Сайте, они могут быть перенаправлены к сайтам
              (приложениям и т.д.) других компаний за пределы хостингового
              пространства АО «Банк ЦентрКредит» («Facebook», «google+»,
              «ВКонтакте), где информация о Пользователях собирается вне прямого
              контроля АО «Банк ЦентрКредит» и Администрации Сайта. В таком
              случае Политики конфиденциальности сайтов и/или приложений третьих
              лиц будут регулировать порядок обработки информации, полученной от
              пользователей этими третьими лицами. АО «Банк ЦентрКредит» и
              Администрация Сайта не несут ответственности за переданную
              Пользователем этим третьим лицам информацию.
              <br />
              Если Пользователь решил войти на сайт, используя службу
              аутентификации стороннего оператора, например: информацию
              Facebook, Общество может получить дополнительный профиль, или
              другую информацию, доступ к которой разрешен Пользователем в
              аккаунте стороннего оператора для предоставления третьим лицам.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
            </h3>
            <p>
              1.1. В Политике конфиденциальности используются следующие термины:
              <br />
              1.1.1. «Администрация сайта» — уполномоченные сотрудники,
              действующие от имени АО «Банк ЦентрКредит», которые организуют и
              (или) осуществляют Обработку конфиденциальных данных, а также
              определяет цели обработки конфиденциальных данных, состав
              конфиденциальных данных, подлежащих обработке, действия
              (операции), совершаемые с конфиденциальными данными.
              <br />
              1.1.2. Конфиденциальные данные — данные, оставляемые Пользователем
              сайта в формах обратной связи и заявок на подключение услуг,
              Cookies, а также автоматически передаваемые данные в процессе
              использования сайта, в том числе, но не ограничиваясь: IP-адрес,
              сведения об устройстве, с которого осуществляется доступ и т.д.
              <br />
              1.1.3. «Персональные данные» — сведения, относящиеся к
              определенному или определяемому на их основании субъекту
              персональных данных, зафиксированные на электронном, бумажном и
              (или) ином материальном носителе.
              <br />
              1.1.4. «Обработка персональных данных» — любое действие (операция)
              или совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (предоставление, доступ),
              обезличивание, блокирование, удаление, уничтожение персональных
              данных.
              <br />
              1.1.5. «Конфиденциальность персональных данных» — обязательное для
              соблюдения Оператором или иным получившим доступ к персональным
              данным лицом требование не допускать их распространения без
              согласия субъекта персональных данных или наличия иного законного
              основания.
              <br />
              1.1.6. «Пользователь» сайта https://admin.bikli.kz/ — лицо,
              имеющее доступ к Сайту посредством сети Интернет и использующее
              Сайт.
              <br />
              1.1.7. «Сайт» — веб-сайт, расположенный в сети Интернет на
              доменном адресе https://admin.bikli.kz/.
              <br />
              1.1.8. «Cookies» — небольшой фрагмент данных, отправленный
              веб-сервером и хранимый на компьютере Пользователя, который
              веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
              HTTP-запросе при попытке открыть страницу соответствующего сайта.
              <br />
              1.1.9. «IP-адрес» — уникальный сетевой адрес узла в компьютерной
              сети, построенной по протоколу IP.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
            </h3>
            <p>
              2.1. Политика конфиденциальности устанавливает обязательства
              Администрации сайта по неразглашению и обеспечению режима защиты
              конфиденциальности данных, которые Пользователь предоставляет
              Администрации сайта при работе с сайтом, в том числе при подаче
              заявки на подключение.
              <br />
              2.2. Конфиденциальные данные, разрешенные к Обработке в рамках
              Политики конфиденциальности, предоставляются Пользователем путем
              заполнения регистрационной формы (пример представлен ниже), форм
              обратной связи и включают в себя – Наименование Пользователя
              (указывается по усмотрению Пользователя), телефон, почта (адрес
              электронной почты) Юридического лица. Если в форме заявки
              Пользователь вместо контактных данных юридического лица оставляет
              свои персональные данные, то Общество обеспечивает их защиту в
              соответствии с требованиями Законодательства Республики Казахстан
              о персональных данных.
              <br />
              2.3. Кроме регистрационной формы на Сайте могут использоваться
              различные системы веб- аналитики. Сайт защищает данные, которые
              автоматически передаются в процессе просмотра рекламных блоков и
              при посещении страниц Сайта, в том числе:
              <br />• IP-адрес;
              <br />• информация из Cookies;
              <br />• информация о браузере (или иной программе, которая
              осуществляет доступ к показу рекламы);
              <br />• операционная система;
              <br />• время доступа, часовой пояс/регион;
              <br />• адрес страницы Сайта.
              <br />
              2.3.1. Отключение Cookies может повлечь невозможность доступа к
              некоторым разделам Cайта.
              <br />
              2.3.2. Администрация сайта может осуществлять сбор статистики в
              отношении данных, указанных в п. 2.3. Политики конфиденциальности.
              Данная информация используется с целью выявления и решения
              технических проблем, для противодействия мошенничеству.
              <br />
              2.3.3. Информация подлежит надежному хранению и нераспространению,
              за исключением случаев, предусмотренных Политикой
              конфиденциальности и законодательством Республики Казахстан.
              <br />
              2.3.5. Общество и Администрация сайта вправе предоставлять
              информацию Пользователей своим
              <br />
              аффилированным лицам (в том числе осуществляющих свою деятельность
              за пределами территории Республики Казахстан), а также иным
              компаниям, связанным с Обществом в целях, указанных выше. При этом
              аффилированные лица, а также связанные с ним компании обязаны
              придерживаться положений настоящей Политики.
              <br />
              2.3.6. Обществом не предоставляется информация Пользователей
              компаниям и частным лицам, не связанным с Обществом, за
              исключением перечисленных ниже случаев:
              <br />• Пользователь дал на это свое согласие;
              <br />• По основаниям и в порядке, установленным законодательством
              Республики Казахстан, в том числе с целью:
              <br />– выполнения судебных решений или исполнения законных
              требований государственных органов;
              <br />– устранения технических сбоев или проблем безопасности;
              <br />– защиты прав, собственности или безопасности Общества,
              пользователей Сервисов Общества в рамках, допускаемых действующим
              законодательством.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              3. ПОРЯДОК ДЕЙСТВИЯ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ И ВНЕСЕНИЯ В НЕЕ
              ИЗМЕНЕНИЙ
            </h3>
            <p>
              3.1. Политика вступает в силу на неограниченный срок с момента ее
              утверждения Обществом. Действующая Политика признается
              недействительной после ее замены новой Политикой. Замена Политики
              осуществляется путем публикации ее новой редакции на Сайте.
              <br />
              3.2. Действующая редакция Политики, являющаяся публичным
              документом, доступна любому пользователю сети Интернет при
              переходе по ссылке https://admin.bikli.kz/.
              <br />
              Администрация Сайта вправе вносить изменения в Политику, которые
              не противоречат законодательству Республики Казахстан. Размещение
              на Сайте новой редакции Политики после ее утверждения является
              уведомлением пользователей Сайта о внесенных в Политику
              изменениях.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              4. УСЛОВИЯ И ЦЕЛИ ОБРАБОТКИ КОНФИДЕНЦИАЛЬНЫХ ДАННЫХ
            </h3>
            <p>
              4.1. Администрация Сайта осуществляет обработку конфиденциальных
              данных Пользователя в следующих целях:
              <br />- рассмотрение заявки Пользователя на подключение
              юридическому лицу/индивидуальному предпринимателю услуг,
              предоставляемых АО «Банк ЦентрКредит», а также взаимодействие в
              целях сбора необходимых документов и сведений для заключения
              соответствующего договора с АО «Банк ЦентрКредит» и (или) его
              партнерами, консультирование о предоставляемых услугах,
              информирование о действующих и новых продуктах АО «Банк
              ЦентрКредит» и его партнеров.
              <br />- осуществление технического управления сайтом, а также для
              проведения анализа и улучшения его работы.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              5. СПОСОБЫ И СРОКИ ОБРАБОТКИ КОНФИДЕНЦИАЛЬНЫХ ДАННЫХ
            </h3>
            <p>
              5.1. Обработка данных Пользователя осуществляется без ограничения
              срока любым законным способом, в том числе в информационных
              системах персональных данных с использованием средств
              автоматизации или без использования таких средств.
              <br />
              5.2. Пользователь дает согласие на обработку конфиденциальных
              данных без ограничения срока.
              <br />
              5.3. Данные Пользователя могут быть переданы уполномоченным
              органам государственной власти Республики Казахстан только по
              основаниям и в порядке, установленными законодательством
              Республики Казахстан.
              <br />
              5.4. Администрация сайта обязана принимать необходимые
              организационные и технические меры для обеспечения
              конфиденциальности, целостности, сохранности и доступности
              конфиденциальных данных Пользователей, а также соблюдать иные
              требования к обработке данных, установленные законодательством
              Республики Казахстана.
              <br />
              5.5. При утрате или разглашении конфиденциальных данных
              Администрация сайта информирует Пользователя об утрате или
              разглашении его данных.
              <br />
              5.6. Администрация сайта совместно с Пользователем принимает все
              необходимые меры по предотвращению убытков или иных отрицательных
              последствий, вызванных утратой или разглашением конфиденциальных
              данных Пользователя.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              6. ОБЯЗАТЕЛЬСТВА СТОРОН
            </h3>
            <p>
              6.1. Пользователь обязан:
              <br />
              6.1.1. Предоставить актуальные данные, запрашиваемые Сайтом в
              момент его использования.
              <br />
              6.1.2. Обновить, дополнить предоставленные данные в случае
              изменения данной информации.
              <br />
              6.1.3. Предварительно получить все необходимые согласия от третьих
              лиц в случае предоставления их данных Администрации сайта.
              <br />
              <br />
              6.2. Администрация сайта обязана:
              <br />
              6.2.1. Использовать полученные конфиденциальные данные
              исключительно для целей, указанных в Разделе 4 Политики
              конфиденциальности.
              <br />
              6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
              разглашать ее без предварительного письменного разрешения
              Пользователя, а также не осуществлять продажу, обмен,
              опубликование, либо разглашение иными возможными способами
              конфиденциальных данных, за исключением случаев, предусмотренных
              Политикой конфиденциальности, заключенными договорами с клиентами,
              законодательством Республики Казахстан.
              <br />
              6.2.3. Принимать меры предосторожности для защиты
              конфиденциальности данных Пользователя согласно порядку, обычно
              используемого для защиты такого рода информации в существующем
              деловом обороте, и в соответствии с требованиями законодательства
              Республики Казахстан.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              7. ОТВЕТСТВЕННОСТЬ СТОРОН
            </h3>
            <p>
              7.1. Администрация сайта, не исполнившая свои обязательства, несёт
              ответственность за убытки, понесённые Пользователем в связи с
              неправомерным использованием конфиденциальных данных, в
              соответствии с законодательством Республики Казахстан, за
              исключением случаев, предусмотренных п.7.2. настоящей Политики
              Конфиденциальности.
              <br />
              7.2. В случае утраты или разглашения Конфиденциальной информации
              Администрация сайта не несёт ответственность, если данная
              конфиденциальная информация:
              <br />
              7.2.1. Стала публичным достоянием до её утраты или разглашения.
              <br />
              7.2.2. Была получена от третьей стороны до момента её получения
              Администрацией сайта.
              <br />
              7.2.3. Была разглашена с согласия Пользователя.
              <br />
              7.3. Пользователь возмещает АО «Банк ЦентрКредит» в полном объеме
              ущерб в случае предъявления третьими лицами, в том числе
              государственными органами, к АО «Банк ЦентрКредит» претензий,
              исков, касающихся Обработки Персональных данных, переданных
              Пользователем Администрации сайта без согласия субъекта
              персональных данных, Обработки
              недостоверных/искаженных/неактуальных Персональных данных по вине
              Пользователя.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              8. РАЗРЕШЕНИЕ СПОРОВ
            </h3>
            <p>
              8.1. До обращения в суд с иском по спорам, возникающим из
              отношений между Пользователем сайта https://admin.bikli.kz/ и
              Администрацией сайта, обязательным является предъявление претензии
              (письменного предложения о добровольном урегулировании спора).
              <br />
              8.2. Получатель претензии в течение 30 (Тридцати) календарных дней
              со дня получения претензии, письменно уведомляет заявителя
              претензии о результатах рассмотрения претензии.
              <br />
              8.3. При не достижении соглашения спор будет передан на
              рассмотрение в судебный орган в соответствии с действующим
              законодательством Республики Казахстан.
              <br />
              8.4. К настоящей Политике конфиденциальности и отношениям между
              Пользователем и Администрацией сайта применяется действующее
              законодательство Республики Казахстан.
            </p>
            <h3 style={{ marginBottom: "5px", marginTop: "20px" }}>
              9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
            </h3>
            <p>
              9.1. Обработка персональных данных осуществляется с момента
              заключения договоров АО «Банк ЦентрКредит» с клиентами и
              регулируется их условиями.
              <br />
              9.2. Пользователь/субъект персональных данных в любое время может
              отозвать согласие на Обработку Персональных данных посредством
              направления письменного заявления АО «Банк ЦентрКредит» по адресу:
              г. Алматы, пр. Аль-Фараби 38. В указанном случае Администрация
              сайта может продолжить обрабатывать Персональные данные без
              согласия Пользователя/субъекта Персональных данных в случаях,
              установленных законодательством Республики Казахстан.
              <br />
              9.3. Пользователь/субъект персональных данных имеет право
              реализовать свои права, предусмотренные Главой 4 Закона Республики
              Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их
              защите», в том числе получить информацию, касающуюся Обработки
              персональных данных, потребовать уточнения или блокирования, или
              уничтожения Персональных данных в случаях, предусмотренных
              законом, принимать меры по защите своих прав. Взаимодействие
              осуществляется в письменной форме по адресу, указанному в п. 9.2.
              Политики конфиденциальности.
              <br />
              9.4. К настоящей Политике конфиденциальности и отношениям между
              Пользователем и Администрацией сайта, возникающим в связи с
              применением Политики конфиденциальности, подлежит применению право
              Республики Казахстан.
              <br />
              9.5. Оператор вправе вносить изменения в настоящую Политику
              конфиденциальности без согласия Пользователя.
              <br />
              9.6. Новая Политика конфиденциальности вступает в силу с момента
              ее размещения на сайте https://admin.bikli.kz/ , если иное не
              предусмотрено новой редакцией Политики конфиденциальности.
              <br />
              9.7. Пользователь обязуется самостоятельно следить за изменениями
              Политики конфиденциальности путем ознакомления с актуальной
              редакцией.
              <br />
              9.8. Все предложения или вопросы по настоящей Политике
              конфиденциальности следует сообщать посредством направления
              обращения с использованием Сайта.
              <br />
              9.9. Политика конфиденциальности размещена на странице по адресу
              https://admin.bikli.kz/ .
            </p>
          </div>

          <div
            className="agree"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "110px",
            }}
          >
            <label style={{ margin: "0 10% 20px 10%" }}>
              <input
                type="checkbox"
                name="agree"
                onChange={() => setAgreeCheckbox(!agreeCheckbox)}
              />
              Я согласен "Политикой конфиденциальности"
            </label>
            <button
              style={{ padding: "5px" }}
              onClick={() => userAgreed()}
              disabled={agreeCheckbox ? false : true}
            >
              Принять
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={s.payment_zone}>
            <div className={s.amount}>
              <input
                type="number"
                value={amount}
                placeholder='0'
                min={10}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span>₸</span>
            </div>
            <Button size="medium" fill onClick={() => sendPaymentData()}>
              Пополнить баланс
            </Button>
            <div className="fill"></div>
          </div>

          <PaymentOrder />
        </>
      )}
    </>
  );
};

export default ProfileMasterAddBalance;
