/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  setDate,
  filterCategory,
  errorStatus,
  countItem,
  onEmptyBasket,
  getUserData,
  delAdressDeliveryBasket,
  websocketAllOrder,
  websocketMaster,
  accountBeauty,
  accountMasterServices,
} from './redux/action';

import s from './companents/bshop/MainBshop.module.css';
import './companents/bshop/css/app.module.css';
import Footer from './companents/footer/Footer';

import './companents/button/style.css';

import './App.css';
import Header from './companents/headerNew/Header';
import SignInFromInstagram from './companents/SignIn/SignInFromInstagram';
import ProfileBeauty from './pages/beauty/ProfileBeauty';
import MasterRequest from './companents/headerNew/MasterRequest';
import ProfileMasterEdit from './pages/beauty/ProfileBeautyMaster/ProfileMasterEdit';
import ProfileMasterAddBalance from './pages/beauty/ProfileBeautyMaster/ProfileMasterAddBalance';
import RegistrationForm from './pages/beauty/RegistrationForm';
import Notifications from './companents/headerNew/Notifications';
import beautyApi from './api/beautyApi';
import AccepNCancelApp from './companents/headerNew/AccepNCancelApp';
import ForgetPassword from './pages/beauty/ForgetPassword';
import ScheduleWrapper from './companents/beauty/Schedule/ScheduleWrapper';

const App = () => {
  const history = useHistory();
  const ws = useRef(null);
  const interval = useRef(null);
  const dispatch = useDispatch();
  const [wsConnection, setWsConnection] = useState(true);
  const login = JSON.parse(localStorage.getItem('account'));
  const [id, setId] = useState(null);
  let iid = null;

  useEffect(() => {
    if (login) {
      const regdata = new FormData();
      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 1);

      beautyApi.login(regdata).then(async (res) => {
        setId(res[0].id);
        iid = res[0].id;
        dispatch(accountBeauty(res[0]));
        dispatch(accountMasterServices(res[1]));
        localStorage.setItem('id_master', res[0].id);

        const master_req = await beautyApi.MasterServiceRequests(res[0].id);
        if (
          !JSON.parse(localStorage.getItem('applications_master')) ||
          !JSON.parse(localStorage.getItem('applications_master'))?.length
        ) {
          localStorage.setItem('applications_master', JSON.stringify(master_req));
        }
      });
      history.push('/beauty/requests');
    }
    wsConnect();
  }, []);

  const wsConnect = () => {
    ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
    ws.current.onopen = () => {
      console.log('Соединение открыто');
      gettingData();
      clearInterval(interval.current);
    };
    ws.current.onclose = () => {
      console.log('Соединение закрыто');
    };
  };

  const gettingData = useCallback(() => {
    ws.current.onmessage = async (e) => {
      const message = await JSON.parse(e.data);
      message[0]?.allOrder && dispatch(websocketAllOrder(message[0]?.allOrder));
      if (message[1]?.masterAorR && iid === message[0]?.masterAorR[0].data.masterId) {
        dispatch(websocketMaster(message[1]?.masterAorR));
      }
    };
  }, []);

  return (
    <div className="app">
      <>
        <Header title={'Beauty'} type="beauty">
          <div className={s.notification}>
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_1269_11884" fill="white">
                <path d="M17.7296 19.5H2.47607C1.10857 19.5 0 18.3914 0 17.0239C0 16.5752 0.121965 16.1348 0.352857 15.75C1.82514 13.2962 2.60286 10.4884 2.60286 7.62679V6C2.60286 2.68629 5.28915 0 8.60286 0H11.6029C14.9166 0 17.6029 2.68629 17.6029 6V7.62679C17.6029 10.4884 18.3806 13.2962 19.8529 15.75C20.5564 16.9226 20.1762 18.4436 19.0036 19.1471C18.6188 19.378 18.1784 19.5 17.7296 19.5ZM13.8529 21.75C12.8529 23.25 11.6029 24 10.1029 24C8.60286 24 7.35286 23.25 6.35286 21.75" />
              </mask>
              <path
                d="M0.352857 15.75L1.72485 16.5732L0.352857 15.75ZM19.8529 15.75L21.2248 14.9268L19.8529 15.75ZM19.0036 19.1471L18.1804 17.7752L19.0036 19.1471ZM15.1841 22.6375C15.6743 21.9023 15.4756 20.9089 14.7404 20.4187C14.0051 19.9286 13.0117 20.1272 12.5216 20.8625L15.1841 22.6375ZM7.68414 20.8625C7.19397 20.1272 6.20058 19.9286 5.46534 20.4187C4.73009 20.9089 4.53141 21.9023 5.02158 22.6375L7.68414 20.8625ZM17.7296 17.9H2.47607V21.1H17.7296V17.9ZM2.47607 17.9C1.99223 17.9 1.6 17.5078 1.6 17.0239H-1.6C-1.6 19.2751 0.224917 21.1 2.47607 21.1V17.9ZM1.6 17.0239C1.6 16.8651 1.64315 16.7093 1.72485 16.5732L-1.01913 14.9268C-1.39922 15.5603 -1.6 16.2852 -1.6 17.0239H1.6ZM1.72485 16.5732C3.34633 13.8707 4.20286 10.7784 4.20286 7.62679H1.00286C1.00286 10.1984 0.303953 12.7217 -1.01913 14.9268L1.72485 16.5732ZM4.20286 7.62679V6H1.00286V7.62679H4.20286ZM4.20286 6C4.20286 3.56995 6.1728 1.6 8.60286 1.6V-1.6C4.40549 -1.6 1.00286 1.80264 1.00286 6H4.20286ZM8.60286 1.6H11.6029V-1.6H8.60286V1.6ZM11.6029 1.6C14.0329 1.6 16.0029 3.56995 16.0029 6H19.2029C19.2029 1.80264 15.8002 -1.6 11.6029 -1.6V1.6ZM16.0029 6V7.62679H19.2029V6H16.0029ZM16.0029 7.62679C16.0029 10.7784 16.8594 13.8707 18.4809 16.5732L21.2248 14.9268C19.9018 12.7217 19.2029 10.1984 19.2029 7.62679H16.0029ZM18.4809 16.5732C18.7298 16.9881 18.5953 17.5262 18.1804 17.7752L19.8268 20.5191C21.7571 19.3609 22.3831 16.8572 21.2248 14.9268L18.4809 16.5732ZM18.1804 17.7752C18.0442 17.8568 17.8884 17.9 17.7296 17.9V21.1C18.4684 21.1 19.1933 20.8992 19.8268 20.5191L18.1804 17.7752ZM12.5216 20.8625C11.7495 22.0205 10.9548 22.4 10.1029 22.4V25.6C12.251 25.6 13.9562 24.4795 15.1841 22.6375L12.5216 20.8625ZM10.1029 22.4C9.25096 22.4 8.45618 22.0205 7.68414 20.8625L5.02158 22.6375C6.24954 24.4795 7.95475 25.6 10.1029 25.6V22.4Z"
                fill="#404246"
                mask="url(#path-1-inside-1_1269_11884)"
              />
            </svg>
          </div>
        </Header>
        <Switch>
          <>
            {/* <Route path="/" render={({ match }) => <ChoosePage match={match} />} exact /> */}
            <Route path="/" render={({ match }) => <ProfileBeauty ws={ws.current} />} exact />

            <div className="app-inner">
              <Switch>
                <Route path="/beauty/profile" render={() => <ProfileBeauty />} exact />
                <Route path="/beauty/master/edit" render={() => <ProfileMasterEdit />} exact />
                <Route
                  path="/beauty/master/addbalance"
                  render={() => <ProfileMasterAddBalance />}
                  exact
                />

                <Route
                  path="/beauty/requests"
                  render={({ match }) => <MasterRequest match={match} ws={ws.current} />}
                  exact
                />

                <Route
                  path="/beauty/registration"
                  render={({ match }) => <RegistrationForm />}
                  exact
                />

                <Route
                  path="/beauty/acceptncanceled"
                  render={({ match }) => <AccepNCancelApp ws={ws.current} />}
                  exact
                />

                <Route
                  path="/beauty/notification"
                  render={({ match }) => <Notifications id={id} />}
                  exact
                />

                <Route
                  path="/beauty/forgetpassword"
                  render={({ match }) => <ForgetPassword />}
                  exact
                />

                <Route
                  path="/authfromweb"
                  render={({ match }) => <SignInFromInstagram id={id} />}
                  exact
                />

                <Route path="/beauty/add_day" render={({ match }) => <ScheduleWrapper />} exact />
              </Switch>
            </div>
          </>
        </Switch>
        <Footer type="beauty" />
      </>
    </div>
  );
};

const mapStateToProps = (data) => data;

const mapDispatchToProps = {
  setDate,
  filterCategory,
  errorStatus,
  countItem,
  onEmptyBasket,
  getUserData,
  delAdressDeliveryBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
