import React from 'react';
import { useDispatch } from 'react-redux';

import DatePicker from '../../../modifiedPackages/react-horizontal-strip-datepicker';
import s from './schedule.module.css';
import '../../../modifiedPackages/react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css';
import ScheduleRecord from './ScheduleRecord';
import { getDate } from '../../../redux/action';

const ScheduleSecond = () => {
  const options = { month: 'long', day: 'numeric' };
  const dispatch = useDispatch();

  const createArray = (s, n, m) =>
    Array.from(
      { length: s },
      () =>
        '2022-' +
        '10-' +
        String(Math.floor(Math.random() * (Math.max(n, m) - Math.min(n, m) - 1)) + Math.min(n, m)),
    );

  const weekends = createArray(3, 1, 30);
  const daysWithAppointment = createArray(3, 16, 30);

  const onSelectedDay = (d) => {
    const dateType1 = new Date(d).toLocaleDateString('ru-ru', options);
    const dateType2 = d.toLocaleDateString('en-CA');
    const sendDate = { dateType1, dateType2 };
    dispatch(getDate(sendDate));
  };
  return (
    <div className={s.root}>
      <div className={s.DatePicker}>
        <DatePicker
          selectedDay={onSelectedDay}
          weekends={weekends}
          daysWithAppointment={daysWithAppointment}
          enableScroll={true}
          enableDaysBefore={4}
        />
      </div>

      <ScheduleRecord />
    </div>
  );
};

export default ScheduleSecond;
