import React from 'react';
import '../bshop/css/index.css';
import HeaderBeauty from './HeaderBeauty';
import AppStore from '../../asseets/app-store 1.png';
import GooglePlay from '../../asseets/google-play 1.png';

const HeaderBshop = ({ title, children, type }) => {
  if (type === 'beauty') {
    return <HeaderBeauty title={title} children={children} />;
  }
};

export default HeaderBshop;
