import React, { useState } from "react";

import s from "./PaymentOrder.module.css";
import visa_logo from "./assets/visa_logo.png";
import mastercard_logo from "./assets/mastercard_logo.png";

function PaymentOrder() {
    const [isExpand, setIsExpand] = useState(false);

    return (
            <div className={s.info}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        setIsExpand(!isExpand);
                    }}
                >
                    Порядок оплаты
                </a>

                <div
                    className={s.text}
                    style={{
                        height: isExpand == true ? "500px" : "0",
                    }}
                >
                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Платежи. Оплата банковской картой онлайн
                    </h2>
                    <p style={{ marginBottom: "15px" }}>
                        Наш сайт подключен к интернет-эквайрингу, и Вы можете
                        оплатить Услугу банковской картой Visa или Mastercard.
                        После подтверждения выбранного Товара либо услуги
                        откроется защищенное окно с платежной страницей АО Банк
                        ЦентрКредит, где Вам необходимо ввести данные Вашей
                        банковской карты. Для дополнительной аутентификации
                        держателя карты используется протокол 3-D Secure. Если
                        Ваш Банк-эмитент поддерживает данную технологию, Вы
                        будете перенаправлены на его сервер для прохождения
                        дополнительной идентификации. Информацию о правилах и
                        методах дополнительной идентификации уточняйте в Банке,
                        выдавшем Вам банковскую карту.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Услуга онлайн-оплаты осуществляется в соответствии с
                        правилами Международных платежных систем Visa и
                        MasterCard на принципах соблюдения конфиденциальности и
                        безопасности совершения платежа, для этого используются
                        самые актуальные методы проверки, шифрования и передачи
                        данных по закрытым каналам связи. Ввод данных банковской
                        карты осуществляется в защищенном окне на платежной
                        странице АО Банк ЦентрКредит.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        В поля на платежной странице требуется ввести номер
                        карты, имя владельца карты, срок действия карты,
                        трёхзначный код безопасности (CVV2 для VISA или CVC2 для
                        MasterCard). Все необходимые данные отображены на
                        поверхности банковской карты.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        CVV2/ CVC2 — это трёхзначный код безопасности,
                        находящийся на оборотной стороне карты.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Далее в том же окне откроется страница Вашего
                        банка-эмитента для ввода 3-D Secure кода. В случае, если
                        у вас не настроен статичный 3-D Secure, он будет
                        отправлен на ваш номер телефона посредством SMS. Если
                        3-D Secure код к Вам не пришел, то следует обратится в
                        ваш банк-эмитент.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        3-D Secure — это самая современная технология
                        обеспечения безопасности платежей по картам в сети
                        интернет. Позволяет однозначно идентифицировать
                        подлинность держателя карты, осуществляющего операцию, и
                        максимально снизить риск мошеннических операций по
                        карте.
                    </p>

                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Гарантии безопасности
                    </h2>
                    <p style={{ marginBottom: "15px" }}>
                        АО Банк ЦентрКредит защищает и обрабатывает данные Вашей
                        банковской карты по стандарту безопасности PCI DSS 3.0.
                        Передача информации в платежный шлюз происходит с
                        применением технологии шифрования SSL. Дальнейшая
                        передача информации происходит по закрытым банковским
                        сетям, имеющим наивысший уровень надежности. АО Банк
                        ЦентрКредит не передает данные Вашей карты нам и иным
                        третьим лицам. Для дополнительной аутентификации
                        держателя карты используется протокол 3-D Secure.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        В случае, если у Вас есть вопросы по совершенному
                        платежу, Вы можете обратиться в службу поддержки
                        клиентов платежного сервиса по электронной почте
                        _665090@gmail.com.
                    </p>
                    <h2 style={{ marginBottom: "15px" }}>
                        Безопасность онлайн-платежей
                    </h2>
                    <p style={{ marginBottom: "15px" }}>
                        Предоставляемая Вами персональная информация (имя,
                        адрес, телефон, e-mail, номер кредитной карты) является
                        конфиденциальной и не подлежит разглашению. Данные Вашей
                        кредитной карты передаются только в зашифрованном виде и
                        не сохраняются на нашем Web-сервере.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Безопасность обработки Интернет-платежей гарантирует АО
                        Банк ЦентрКредит. Все операции с платежными картами
                        происходят в соответствии с требованиями VISA
                        International, MasterCard и других платежных систем. При
                        передаче информации используются специализированные
                        технологии безопасности карточных онлайн-платежей,
                        обработка данных ведется на безопасном
                        высокотехнологичном сервере процессинговой компании.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Оплата платежными картами безопасна, потому что:
                    </p>
                    <ul style={{ marginLeft: "20px", marginBottom: "15px" }}>
                        <li>
                            Система авторизации гарантирует покупателю, что
                            платежные реквизиты его платежной карты (номер, срок
                            действия, CVV2/CVC2) не попадут в руки мошенников,
                            так как эти данные не хранятся на сервере
                            авторизации и не могут быть похищены.
                        </li>
                        <li>
                            Покупатель вводит свои платежные данные
                            непосредственно в системе авторизации АО Банк
                            ЦентрКредит, а не на сайте интернет-магазина,
                            следовательно, платежные реквизиты карточки
                            покупателя не будут доступны третьим лицам.
                        </li>
                    </ul>
                    <div
                        className="payment_systems"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={visa_logo}
                            alt="Visa Logo"
                            style={{ height: "30px" }}
                        />
                        <img
                            src={mastercard_logo}
                            alt="Mastercard Logo"
                            style={{ height: "50px", marginLeft: "15px" }}
                        />
                    </div>

                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Правила доставки
                    </h2>
                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Возврат денежных средств
                    </h2>
                    <p style={{ marginBottom: "15px" }}>
                        При проведении онлайн-оплаты посредством платежных карт
                        не допускается возврат наличными денежными средствами.
                        Порядок возврата регулируется правилами международных
                        платежных систем:
                    </p>
                    <ol style={{ marginLeft: "20px", marginBottom: "15px" }}>
                        <li>
                            Потребитель вправе отказаться от товара в любое
                            время до его передачи, после передачи товара отказ
                            необходимо оформить в течение 14 дней;
                        </li>
                        <li>
                            Возврат товара надлежащего качества возможен в
                            случае, если сохранены его товарный вид,
                            потребительские свойства, а также документ,
                            подтверждающий факт и условия покупки указанного
                            товара;
                        </li>
                        <li>
                            Потребитель не вправе отказаться от товара
                            надлежащего качества, имеющего
                            индивидуально-определенные свойства, если указанный
                            товар может быть использован исключительно
                            приобретающим его человеком;
                        </li>
                        <li>
                            При отказе от товара со стороны потребителя продавец
                            должен вернуть ему денежную сумму, уплаченную
                            потребителем, не позднее чем через десять дней со
                            дня предъявления потребителем соответствующего
                            требования.
                        </li>
                    </ol>
                    <p style={{ marginBottom: "15px" }}>
                        Для возврата денежных средств на банковскую карту
                        необходимо заполнить «Заявление о возврате денежных
                        средств», которое высылается по требованию компанией на
                        электронный адрес, и отправить его вместе с приложением
                        копии документа, удостоверяющего личность, по адресу
                        _665090@gmail.com.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Возврат денежных средств будет осуществлен на банковскую
                        карту в течение ___ рабочего дня со дня получения
                        «Заявление о возврате денежных средств» Компанией.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Для возврата денежных средств по операциям, проведенным
                        с ошибками, необходимо обратиться с письменным
                        заявлением и приложением копии документа,
                        удостоверяющего личность, и чеков/квитанций,
                        подтверждающих ошибочное списание. Данное заявление
                        необходимо направить по адресу _665090@gmail.com.
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Сумма возврата будет равняться сумме покупки. Срок
                        рассмотрения Заявления и возврата денежных средств
                        начинает исчисляться с момента получения Компанией
                        Заявления и рассчитывается в рабочих днях без учета
                        праздников/выходных дней.
                    </p>

                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Случаи отказа в совершении платежа:
                    </h2>
                    <ul style={{ marginLeft: "20px", marginBottom: "15px" }}>
                        <li>
                            банковская карта не предназначена для совершения
                            платежей через интернет, о чем можно узнать,
                            обратившись в Ваш Банк-эмитент;
                        </li>
                        <li>
                            недостаточно средств для оплаты на банковской карте.
                            Подробнее о наличии средств на платежной карте Вы
                            можете узнать, обратившись в банк, выпустивший
                            банковскую карту;
                        </li>
                        <li>данные банковской карты введены неверно;</li>
                        <li>
                            истек срок действия банковской карты. Срок действия
                            карты, как правило, указан на лицевой стороне карты
                            (это месяц и год, до которого действительна карта).
                            Подробнее о сроке действия карты Вы можете узнать,
                            обратившись в банк-эмитент.
                        </li>
                    </ul>
                    <p style={{ marginBottom: "15px" }}>
                        По вопросам оплаты с помощью банковской карты и иным
                        вопросам, связанным с работой сайта, Вы можете
                        обратиться по следующим телефонам:{" "}
                        <a href="tel:87182665090">8 7182 665090</a>
                    </p>

                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Конфиденциальность
                    </h2>
                    <h3 style={{ marginBottom: "15px" }}>1. Определения</h3>
                    <p style={{ marginBottom: "15px" }}>
                        Интернет проект https://admin.bikli.kz/ (далее – URL,
                        «мы») серьезно относится к вопросу конфиденциальности
                        информации своих клиентов и посетителей сайта
                        https://admin.bikli.kz/ (далее – «вы», «посетители
                        сайта»). Персонифицированной мы называем информацию,
                        содержащую персональные данные (например: ФИО, логин или
                        название компании) посетителя сайта, а также информацию
                        о действиях, совершаемых вами на сайте URL. (например:
                        заказ посетителя сайта с его контактной информацией).
                        Анонимными мы называем данные, которые невозможно
                        однозначно идентифицировать с конкретным посетителем
                        сайта (например: статистика посещаемости сайта).
                    </p>
                    <h3 style={{ marginBottom: "15px" }}>
                        2. Использование информации
                    </h3>
                    <p style={{ marginBottom: "15px" }}>
                        Мы используем персонифицированную информацию конкретного
                        посетителя сайта исключительно для обеспечения ему
                        качественного оказания услуг и их учета. Мы не
                        раскрываем персонифицированных данных одних посетителей
                        сайта URL другим посетителям сайта. Мы никогда не
                        публикуем персонифицированную информацию в открытом
                        доступе и не передаем ее третьим лицам. Исключением
                        являются лишь ситуации, когда предоставление такой
                        информации уполномоченным государственным органам
                        предписано действующим законодательством Республики
                        Казахстан. Мы публикуем и распространяем только отчеты,
                        построенные на основании собранных анонимных данных. При
                        этом отчеты не содержат информацию, по которой было бы
                        возможным идентифицировать персонифицированные данные
                        пользователей услуг. Мы также используем анонимные
                        данные для внутреннего анализа, целью которого является
                        развитие продуктов и услуг URL
                    </p>
                    <h3 style={{ marginBottom: "15px" }}>3. Ссылки</h3>
                    <p style={{ marginBottom: "15px" }}>
                        Сайт https://admin.bikli.kz/ может содержать ссылки на
                        другие сайты, не имеющие отношения к нашей компании и
                        принадлежащие третьим лицам. Мы не несем ответственности
                        за точность, полноту и достоверность сведений,
                        размещенных на сайтах третьих лиц, и не берем на себя
                        никаких обязательств по сохранению конфиденциальности
                        информации, оставленной вами на таких сайтах.
                    </p>
                    <h3 style={{ marginBottom: "15px" }}>
                        4. Ограничение ответственности
                    </h3>
                    <p style={{ marginBottom: "15px" }}>
                        Мы делаем все возможное для соблюдения настоящей
                        политики конфиденциальности, однако, мы не можем
                        гарантировать сохранность информации в случае
                        воздействия факторов, находящихся вне нашего влияния,
                        результатом действия которых станет раскрытие
                        информации. Сайт https://admin.bikli.kz/ и вся
                        размещенная на нем информация представлены по принципу
                        "как есть” без каких-либо гарантий. Мы не несем
                        ответственности за неблагоприятные последствия, а также
                        за любые убытки, причиненные вследствие ограничения
                        доступа к сайту URL или вследствие посещения сайта и
                        использования размещенной на нем информации.
                    </p>
                    <h3 style={{ marginBottom: "15px" }}>5. Контакты</h3>
                    <p style={{ marginBottom: "15px" }}>
                        По вопросам, касающимся настоящей политики, просьба
                        обращаться по адресу 665090@gmail.com
                    </p>
                    <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>
                        Юридическое лицо
                    </h2>
                    <p style={{ marginBottom: "15px" }}>ИП «Голышев С.А.»</p>
                    <p style={{ marginBottom: "15px" }}>
                        Реквизиты счета для перечисления ИИК/БИК:
                        KZ768562204123206324
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Юридический адрес места нахождения: Казахстан 398
                        Павлодарская обл. г. Павлодар ул. УЛИЦА КУТУЗОВА д. 15
                        кв. (офис) 20
                    </p>
                    <p style={{ marginBottom: "15px" }}>
                        Фактический адрес места нахождения: Казахстан 398
                        Павлодарская обл. г. Павлодар ул. Луначарского 10 офис 8
                    </p>
                </div>
            </div>
    );
}

export default PaymentOrder;
