import beautyApi from "../../../api/beautyApi";
import s from "./schedule.module.css";

const SchedulePopup = ({ master, setViewClient, setClient, dataTime, getSchedule, dateType2, client, isButtonVisible, isDone }) => {
    const closeModal = () => {
        setViewClient(false);
        setClient([]);
    };

    const acceptedClient = (id, clientId) => {
        const fd = new FormData();
        fd.append("id", id);
        fd.append("idClient", clientId);
        fd.append("idMaster", master.id);
        fd.append("date", dataTime.date);
        fd.append("time", dataTime.time);
        fd.append("status", 1);
        const res = beautyApi.acceptedPersonalClient(fd);
        res.then((res) => {
            getSchedule(master.id, dateType2);
            setViewClient(false);
        });
    };

    const canceledClient = (id, clientId) => {
        const fd = new FormData();
        fd.append("id", id);
        fd.append("idClient", clientId);
        fd.append("idMaster", master.id);
        fd.append("status", 2);
        fd.append("date", dataTime.date);
        fd.append("time", dataTime.time);
        const res = beautyApi.canceledPersonalClient(fd);
        res.then((res) => {
            getSchedule(master.id, dateType2);
            setViewClient(false);
        });
    };

    const doneClient = (client) => {
        const fd = new FormData();
        fd.append("id_entry", client.id);
        fd.append("id_time", dataTime.id);
        const res = beautyApi.doneClient(fd);
        res.then((res) => {
            if (res) {
                setViewClient(false);
                setClient([]);
                getSchedule(master.id, dateType2);
            }
        });
    };

    if (closeModal) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'visible'
      }
    

    return (
        <div className={s.wrapperModal}>
            <div className={s.modal}>
                <div className={s.closeModal} onClick={closeModal}>
                    &#10006;
                </div>
                <div className={s.wrapForm}>
                    <div className={s.imgNname}>
                        <div className={s.img}>
                            <img
                                src={`https://bshop.kz/beauty/AvatarPhotos/${client?.img || "2022-08-26_10:47:19.jpeg"
                                    }`}
                                alt="person"
                            />
                        </div>
                        <div className={s.namePerson}>{client?.FIO}</div>
                    </div>
                    <div className={s.infoClient}>
                        <div className={s.catOrder}>{client?.cat}</div>
                        <div className={s.nameOrder}>{client?.services}</div>
                        {client?.dop_services && <div className={s.nameOrder}>{client?.dop_services}</div>}
                        <div className={s.dataOrder}>
                            <label>Дата</label>
                            {client?.date}
                        </div>
                        <div className={s.timeOrder}>
                            <label>Время</label>
                            {client?.time}
                        </div>
                        <div className={s.priceOrder}>
                            <label>Цена</label>
                            {client?.price}
                        </div>
                    </div>
                </div>
                {isButtonVisible ? (
                    <div className={s.wrapButtons}>
                        <button
                            className={s.buttonCancel}
                            onClick={() => canceledClient(client?.id, client[0]?.id)}
                        >
                            Отклонить
                        </button>
                        <button
                            className={s.buttonAccept}
                            onClick={() => acceptedClient(client?.id, client[0]?.id)}
                        >
                            Принять
                        </button>
                    </div>
                ) : isDone ? (
                    <span style={{ float: "right" }}>Завершен</span>
                ) : (
                    <button
                        className={s.buttonAccept}
                        style={{ margin: "0 auto" }}
                        onClick={() => doneClient(client)}
                    >
                        Завершить
                    </button>
                )}
            </div>
        </div>
    )
}

export default SchedulePopup