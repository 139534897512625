const initialState = {
  onSearchItemAllServRequests: {
    RServicesValue: '',
    RServiceEnd: '3',
    onShopId: '',
    onCategoryId: '',
  },
  date: { dateType1: '-', dateType2: '-' },
  accountBeauty: [],

  itemsAllServR: [],
  itemsAllServRMaster: [],
  PublItems: 4,
  ServItems: 4,
  ServItemsR: 4,
  onShowMasters: true,
  SBroderType: '0.5px solid #404246',
  MBroderType: '4px solid transparent',
  SBorderImage: 'none',
  MBorderImage: 'linear-gradient(to left, #D432D8 -0.07%, #FF608D 51.49%, #F4A582 99.93%)',
  DisplayType: 'flex',
  DopDisplayType: 'block',
  ServicesMaster: [],
  websocketAllOrder: [],
  websocketMaster: [],
  ServMastersMenu: 'Запись',
  itemsAllMasterServR: [],
  CalendarReqDay: [],
  headerMargin: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TO_SET_CalendarReqDay': {
      return {
        ...state,
        CalendarReqDay: action.payload,
      };
    }
    case 'TO_ADD_ITEM_ALL_MASTER_SERV_R': {
      return {
        ...state,
        itemsAllMasterServR: action.payload,
      };
    }

    case 'TO_ON_SERVMASTERS_MENU': {
      return {
        ...state,
        ServMastersMenu: action.payload,
      };
    }

    case 'TO_WEBSOCKET_ALL': {
      return {
        ...state,
        websocketAllOrder: action.payload,
      };
    }

    case 'TO_WEBSOCKET_MASTER': {
      return {
        ...state,
        websocketMaster: action.payload,
      };
    }

    case 'ON_SEARCH_ITEM_ALL_SERV': {
      return {
        ...state,
        onSearchItemAllServ: action.payload,
      };
    }

    case 'ON_SEARCH_ITEM_ALL_SERV_REQUESTS': {
      return {
        ...state,
        onSearchItemAllServRequests: action.payload,
      };
    }

    case 'ON_SHOW_MASTERS': {
      return {
        ...state,
        onShowMasters: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV_R': {
      return {
        ...state,
        itemsAllServR: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV_R_M': {
      return {
        ...state,
        itemsAllServRMaster: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_PUBL': {
      return {
        ...state,
        PublItems: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_ALL_SERV': {
      return {
        ...state,
        ServItems: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_ALL_SERV_R': {
      return {
        ...state,
        ServItemsR: action.payload,
      };
    }

    case 'TO_ON_ACCOUNT_BEAUTY': {
      return {
        ...state,
        accountBeauty: action.payload,
      };
    }
    case 'TO_SERVICES_MASTER': {
      return {
        ...state,
        ServicesMaster: action.payload,
      };
    }

    case 'GET_DATE': {
      return {
        ...state,
        date: action.payload,
      };
    }

    case 'HEADER_MARGIN': {
      return {
        ...state,
        headerMargin: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
