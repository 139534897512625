import React, { useState } from 'react';
import Schedule from './Schedule';
import ScheduleSecond from './ScheduleSecond';
import s from './schedule.module.css';
import { ReactComponent as ScheduleLogo1 } from '../../../asseets/Group 113.svg';
import { ReactComponent as ScheduleLogo2 } from '../../../asseets/Group 120.svg';

const ScheduleWrapper = ({}) => {
  const [ScheduleType, setScheduleType] = useState('Schedule');

  const handleClickSchedule_1 = () => {
    setScheduleType('Schedule');
  };

  const handleClickSchedule_2 = () => {
    setScheduleType('ScheduleSecond');
  };

  return (
    <>
      <div className={s.ScheduleHeader}>
        <div></div>
        <div></div>

        <div className={s.title}>Журнал заявок</div>
        <div style={{ textAlign: 'right' }} onClick={handleClickSchedule_1}>
          <ScheduleLogo1 />
        </div>
        <div style={{ textAlign: 'center' }} onClick={handleClickSchedule_2}>
          <ScheduleLogo2 />
        </div>
      </div>
      {ScheduleType == 'ScheduleSecond' ? <ScheduleSecond /> : <Schedule />}
    </>
  );
};

export default ScheduleWrapper;
