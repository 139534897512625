import s from './GeneralCalendar.module.css';

import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getDate } from '../../../redux/action';
import { useSelector } from 'react-redux';

const GeneralCalendar = () => {
  const options = { month: 'long', day: 'numeric' };
  const dispatch = useDispatch();
  const selectedDate = useSelector(state => state.date.dateType2)

  const callDay = (clikedDay) => {
    let day = dayjs(clikedDay).format('YYYY-MM-DD');

    const dateType1 = new Date(day).toLocaleDateString('ru-ru', options);
    const dateType2 = dayjs(clikedDay).format('YYYY-MM-DD');

    const sendDate = { dateType1, dateType2 };
    dispatch(getDate(sendDate));
  };

  return (
    <div className={s.content}>
      <Calendar
        defaultValue={selectedDate === '-' ? new Date() : new Date(selectedDate)}
        minDetail='month'
        onClickDay={(value) => callDay(value)}
        next2Label={null}
        prev2Label={null}
      />
    </div>
  );
};

export default GeneralCalendar;
