import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import s from './header.module.css';
import '../bshop/css/index.css';
import beautyApi from '../../api/beautyApi';
import { headerMargin } from '../../redux/action';

const HeaderBeauty = ({ title, children }) => {
  const history = useHistory();
  const url = useLocation();
  const dispatch = useDispatch();

  const header = React.useRef(null);

  const status = useSelector((state) => state.accountBeauty.status);
  const websocketMaster = useSelector((state) => state.websocketMaster);
  const websocketAllOrder = useSelector((state) => state.websocketAllOrder);

  const headerMarginTop = useSelector((state) => state.headerMargin);

  const [dataMaster, setDataMaster] = useState(NaN);

  const login = JSON.parse(localStorage.getItem('account'));

  let responseAllServR = [];
  let responseAllServRFiltered = [];
  let client = [];
  let resServ = [];
  let isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  var arrObjects = [];
  const makeUser = (id, FIO, orderName, date, time, price, comm, clientId, img) => {
    return {
      id: id,
      FIO: FIO,
      orderName: orderName,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      img: img,
      // ...другие свойства
    };
  };

  useEffect(() => {
    getNotif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketMaster]);

  useEffect(() => {
    if (url.pathname !== '/beauty/requests') {
      getNotifAllApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketAllOrder]);

  const goBackfromShop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (isIOS) {
      dispatch(headerMargin(40));
    }
    history.goBack();
  };

  useEffect(() => {
    if (header.current) {
      header.current.style.marginTop = headerMarginTop + 'px';
    }
  }, [headerMarginTop]);

  const goRequests = () => {
    history.push('/beauty/requests');
  };

  const notification = () => {
    setDataMaster(NaN);
    history.push('/beauty/notification');
  };

  const getNotif = async () => {
    let res = [];
    let mainArr = [];
    const id = localStorage.getItem('id_master');
    if (localStorage.getItem('applications_master')) {
      if (!websocketMaster.length) {
        mainArr = await beautyApi.MasterServiceRequests(id);
      } else {
        mainArr = websocketMaster;
      }
      const lSAppMaster = JSON.parse(localStorage.getItem('applications_master'));

      if (mainArr.length !== lSAppMaster.length) {
        res = mainArr.slice(lSAppMaster.length, mainArr.length);
      }
    }
    if (mainArr?.length > 0) {
      for (let i = 0; i < res.length; i = i + 2) {
        if (res[i].data['status'] === '4' || res[i].data['status'] === '1') {
          let user = makeUser(
            res[i].data['id'],
            res[i + 1].client['FIO'],
            res[i].data['orderName'],
            res[i].data['date'],
            res[i].data['time'],
            res[i].data['price'],
            res[i].data['comm'],
            res[i + 1].client['id'],
            res[i + 1].client['img'],
          );
          arrObjects[i] = user;
        }
      }
      if (arrObjects.length) {
        localStorage.setItem('notification_master', JSON.stringify(arrObjects));
        setDataMaster(1);
      }
    }
  };

  const getNotifAllApp = async () => {
    if (login) {
      let arr = [];
      const regdata = new FormData();
      responseAllServR = [];
      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 1);

      await beautyApi.login(regdata).then(async (res) => {
        resServ = await res[1];
      });
      if (resServ.length > 0) {
        if (websocketAllOrder.length === 0) {
          arr = await beautyApi.ClientServiceRequests();

          client = arr;
        } else {
          client = websocketAllOrder;
          arr = websocketAllOrder;
        }
        resServ.forEach((itemServ) => {
          responseAllServR = [
            ...responseAllServR,
            ...arr.filter((item) => item?.data?.cat_id === itemServ.cat_id),
          ];
        });
        responseAllServRFiltered = [];
        responseAllServR = responseAllServR.sort((a, b) => a.data.id - b.data.id);

        responseAllServR.map(async (item, key) => {
          let oneClient = [];
          client.forEach((itemC) => {
            if (itemC?.client?.id === item.data.clientId) {
              oneClient = itemC?.client;
            }
          });
          responseAllServRFiltered.push(
            { ...item },
            {
              client: { ...oneClient },
            },
          );
        });

        if (websocketAllOrder) {
          arr = responseAllServRFiltered;
        } else {
          arr = await websocketAllOrder;
        }
      } else {
        if (websocketAllOrder) {
          arr = await beautyApi.ClientServiceRequests();
        } else {
          arr = await websocketAllOrder;
        }
      }

      const count_app = (arr.length - Number(localStorage.getItem('app_length'))) / 2;
      count_app > 0 && count_app !== Number(localStorage.getItem('app_length')) && setDataMaster(1);
      localStorage.setItem('count_app', count_app);
    }
  };

  return login ? (
    <div className={s.root_beauty} ref={header}>
      <div className={s.arrowBack} onClick={() => goBackfromShop()}>
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.505025 5.50503C0.231658 5.77839 0.231658 6.22161 0.505025 6.49497L4.9598 10.9497C5.23316 11.2231 5.67638 11.2231 5.94975 10.9497C6.22311 10.6764 6.22311 10.2332 5.94975 9.9598L1.98995 6L5.94975 2.0402C6.22311 1.76684 6.22311 1.32362 5.94975 1.05025C5.67638 0.776886 5.23316 0.776886 4.9598 1.05025L0.505025 5.50503ZM13 5.3L1 5.3V6.7L13 6.7V5.3Z"
            fill="#404246"
          />
        </svg>
      </div>

      <div className={s.title_header}>{'Beauty'}</div>
      <div className={s.wrap_celendar_notif}>
        <div className={s.bell_beuaty} onClick={() => notification()}>
          {!isNaN(dataMaster) && <div className={s.bell_beuaty_notification}>{}</div>}
          {children}
        </div>
        {url.pathname !== '/beauty/add_day' && (
          <Link to="/beauty/add_day" style={{ paddingBottom: '0' }}>
            <div className={s.celendar_beauty}>
              <svg
                width="31"
                height="28"
                viewBox="0 0 31 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="1.59697"
                  y="2.7"
                  width="28.6"
                  height="24.6"
                  rx="1.3"
                  stroke="#404246"
                  strokeWidth="1.4"
                  strokeMiterlimit="3.8637"
                />
                <path d="M1.89697 7H21.897" stroke="#404246" strokeLinecap="round" />
                <path d="M29.897 7L27.897 7" stroke="#404246" strokeLinecap="round" />
                <path d="M23.897 7H25.897" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 11H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 11H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 11H13.097" stroke="#404246" strokeLinecap="round" />
                <path d="M15.0969 11H16.4969" stroke="#404246" strokeLinecap="round" />
                <path d="M18.4971 11H19.8971" stroke="#404246" strokeLinecap="round" />
                <path d="M21.897 11H23.297" stroke="#404246" strokeLinecap="round" />
                <path d="M25.2969 11H26.6969" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 14H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 14H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 14H13.097" stroke="#404246" strokeLinecap="round" />
                <path d="M15.0969 14H16.4969" stroke="#404246" strokeLinecap="round" />
                <path d="M18.4971 14H19.8971" stroke="#404246" strokeLinecap="round" />
                <path d="M21.897 14H23.297" stroke="#404246" strokeLinecap="round" />
                <path d="M25.2969 14H26.6969" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 17H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 17H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 17H13.097" stroke="#404246" strokeLinecap="round" />
                <path d="M15.0969 17H16.4969" stroke="#404246" strokeLinecap="round" />
                <path d="M18.4971 17H19.8971" stroke="#404246" strokeLinecap="round" />
                <path d="M21.897 17H23.297" stroke="#404246" strokeLinecap="round" />
                <path d="M25.2969 17H26.6969" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 20H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 20H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 20H13.097" stroke="#404246" strokeLinecap="round" />
                <path d="M15.0969 20H16.4969" stroke="#404246" strokeLinecap="round" />
                <path d="M18.4971 20H19.8971" stroke="#404246" strokeLinecap="round" />
                <path d="M21.897 20H23.297" stroke="#404246" strokeLinecap="round" />
                <path d="M25.2969 20H26.6969" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 23H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 23H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 23H13.097" stroke="#404246" strokeLinecap="round" />
                <path d="M4.89697 23H6.29697" stroke="#404246" strokeLinecap="round" />
                <path d="M8.29688 23H9.69687" stroke="#404246" strokeLinecap="round" />
                <path d="M11.697 23H13.097" stroke="#404246" strokeLinecap="round" />
                <rect
                  x="6.39697"
                  y="0.5"
                  width="2"
                  height="4"
                  rx="1"
                  fill="#F3F3F3"
                  stroke="#404246"
                />
                <rect
                  x="23.397"
                  y="0.5"
                  width="2"
                  height="4"
                  rx="1"
                  fill="#F3F3F3"
                  stroke="#404246"
                />
              </svg>
            </div>
          </Link>
        )}
      </div>
    </div>
  ) : null;
};

export default HeaderBeauty;
