/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useRef } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Inputmask from 'inputmask';

import { useDispatch } from 'react-redux';
import { accountBeauty, accountMasterServices } from '../../redux/action';
import { useHistory } from 'react-router-dom';
import beautyApi from '../../api/beautyApi';

const SignIn = () => {
  const dispatch = useDispatch();
  const [Phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [PhoneError] = useState('');
  const [showPopUpPartner, setshowPopUpPartner] = useState(false);
  const [phoneInput, setphoneInput] = useState(false);
  const [checkClicPhone, setCheckClicPhone] = useState(false);
  const [checkClicPass, setCheckClicPass] = useState(false);

  // const [showFormPartner, setshowFormPartner] = useState(false);
  const time = localStorage.getItem('time');
  const inputP = useRef();
  inputP.current && Inputmask({ mask: '+7 (999)-999-99-99' }).mask(inputP.current);

  useEffect(() => {
    if (/(iPhone|iPad|Windows Phone|WPDesktop|WP7)/i.test(navigator.userAgent)) {
      document.querySelector('body').style.overflow = 'hidden';

      return () => (document.querySelector('body').style.overflow = 'auto');
    }
  }, []);

  const history = useHistory();

  const onPhoneInput = () => {
    inputP.current.value = '';
    setphoneInput(true);
  };

  const wave_error = () => {
    if (Phone === '') {
      toast('Ошибка! Пустое поле Телефона', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (Boolean(PhoneError) === true) {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password === '') {
      toast('Ошибка! Пустое поле пароль', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const wave_success = () =>
    toast('Отлично! Вы успешно авторизовались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const wave_error_sign_in = () =>
    toast.error('Ошибка входа! Вы ввели  неверные данные!', {
      backgroundColor: '#323131',
      color: '#ffffff',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const passwordd = (event) => {
    setPassword(event.target.value);
  };

  const SendRegForm = async (e) => {
    e.preventDefault();
    let authPhone = inputP.current.inputmask.undoValue;
    const regdata = new FormData();
    var now = String(new Date().getTime());
    console.log(now.substr(0, 10));
    time ? console.log('уже есть') : localStorage.setItem('time', now.substr(0, 10));
    regdata.append('phone', authPhone);
    regdata.append('pass', password);
    regdata.append('status', 1);
    let response = await beautyApi.login(regdata);

    let commits = await response; // читаем ответ в формате JSON
    if (commits) {
      wave_success();
      dispatch(accountBeauty(commits[0]));
      dispatch(accountMasterServices(commits[1]));
      localStorage.setItem(
        'account',
        JSON.stringify({ login: authPhone, pass: password, status: 1 }),
      );
      history.push('/beauty/requests');
      // window.location.reload();
    } else {
      wave_error_sign_in();
    }
  };

  const goToReg = () => {
    history.push('/beauty/registration');
  };
  return (
    <div
      className="reg_body"
      style={checkClicPhone ? { position: 'relative', bottom: '35vh' } : null}
      onClick={(e) => {
        e.stopPropagation();
        setCheckClicPhone(false);
      }}>
      <div className="reg_wrapper">
        <div className="inner_text">
          <div className="inner_text_title"> Клиенты уже ждут тебя! </div>
          <div className="inner_text_subtitle">Пройди регистрацию и принимай заявки!</div>
          <button className="reg_abs" style={{ textAlign: 'center' }} onClick={() => goToReg()}>
            Зарегистрироваться
          </button>
        </div>
      </div>
      <div className="reg_wrapper" style={{ marginTop: '4vh' }}>
        <h1 className="reg_title">Авторизация</h1>
        {/* <FormAddPartner 
                showFormPartner={false}
                toggleFormPartner={true}/> */}
        <div className="registration_form">
          <div className="inputGroup">
            <input
              ref={inputP}
              placeholder="Телефон"
              className="reg_placeholder2"
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              onClick={(e) => {
                e.stopPropagation();
                onPhoneInput();
                if (/(iPhone|iPad|Windows Phone|WPDesktop|WP7)/i.test(navigator.userAgent)) {
                } else {
                  setCheckClicPhone(true);
                }
              }}
            />
          </div>

          <Registration_text_field
            name="password"
            onChange={(e) => passwordd(e)}
            error="none"
            placeholder="Пароль"
            className="reg_placeholder"
          />

          <div className="inputGroup">
            <button
              onClick={(e) => SendRegForm(e)}
              className="reg_btn"
              style={{ marginBottom: 20 }}>
              Войти
            </button>
          </div>
          <button
            onClick={() => history.push('/beauty/forgetpassword')}
            className="forgetpassword"
            style={{ marginBottom: 20 }}>
            Забыл пароль?
          </button>
        </div>
      </div>
      {showPopUpPartner ? (
        <div className="popup-info-partner">
          <button className="btn_info_for_partners" onClick={() => setshowPopUpPartner(false)}>
            Закрыть
          </button>

          <div className="popup-info-partner-text">
            <p>
              Мобильное приложение Partner Bikli - это удобный сервис для планирования вашей работы.
            </p>
            <p>Сервис включает: </p>
            <p>- личный кабинет;</p>
            <p>- запись клиентов;</p>
            <p>- публикации работ.</p>
            <p>Вам не нужно больше заниматься поиском клиентов, приложение это сделает за вас.</p>
            <p>С помощью данного приложения вы можете:</p>
            <p>- планировать своё рабочее время;</p>
            <p>- посмотреть свои записи;</p>
            <p>- найти новые заказы;</p>
            <p>- вести портфолио.</p>
            <p>Контакты технической поддержки и обратной связи: </p>
            <p>info.bshop.kz@gmail.com</p>
            <p>тел. +7-718-266-50-90</p>
          </div>
        </div>
      ) : null}
      <button className="btn_info_for_partners" onClick={() => setshowPopUpPartner(true)}>
        Помощь
      </button>
      <ToastContainer
        position="top-center"
        autoClose={500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default SignIn;
