/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import beautyApi from '../../api/beautyApi';

import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import AcceptedOrder from './AcceptedOrder';
import { onAddAllServR, onAddAllServRM } from '../../redux/action';

const AccepNCancelApp = ({ ws }) => {
  const dispatch = useDispatch();

  const websocketMaster = useSelector((state) => state.websocketMaster);
  const websocketAllOrder = useSelector((state) => state.websocketAllOrder);
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const [tab, setTab] = useState(0);

  const tabs = ['Активные', 'Выполненные ', 'Отмененные '];

  let responseAllServR = [];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    getServReqMaster();
    return () => {
      dispatch(onAddAllServR([]));
    };
  }, []);

  useEffect(() => {
    getServReqMaster();
  }, [websocketMaster, websocketAllOrder, tab, accountBeautyInfo]);

  var arrObjectsAccept = [];
  const makeUserAccept = (
    id,
    FIO,
    cat_id,
    orderId,
    orderName,
    orderNameAdd,
    orderName2,
    orderNameAdd2,
    orderName3,
    orderNameAdd3,
    orderName4,
    orderNameAdd4,
    orderName5,
    orderNameAdd5,
    date,
    time,
    price,
    comm,
    clientId,
    status,
    phoneClient,
    Adress,
    SalonName,
    img,
  ) => {
    return {
      id: id,
      FIO: FIO,
      cat_id: cat_id,
      orderId: orderId,
      orderName: orderName,
      orderNameAdd: orderNameAdd,
      orderName2: orderName2,
      orderNameAdd2: orderNameAdd2,
      orderName3: orderName3,
      orderNameAdd3: orderNameAdd3,
      orderName4: orderName4,
      orderNameAdd4: orderNameAdd4,
      orderName5: orderName5,
      orderNameAdd5: orderNameAdd5,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      status: status,
      phoneClient: phoneClient,
      Adress: Adress,
      SalonName: SalonName,
      img: img,
      // ...другие свойства
    };
  };

  const getServReqMaster = async () => {
    if (websocketMaster.length <= 0) {
      responseAllServR = await beautyApi.MasterServiceRequests(accountBeautyInfo.id);
    } else {
      responseAllServR = websocketMaster;
    }

    for (let i = 0; i < responseAllServR.length; i = i + 2) {
      let user = makeUserAccept(
        responseAllServR[i].data['id'],
        responseAllServR[i + 1].client['FIO'],
        responseAllServR[i].data['cat_id'],
        responseAllServR[i].data['orderId'],
        responseAllServR[i].data['orderName'],
        responseAllServR[i].data['orderNameAdd'],
        responseAllServR[i].data['orderName2'],
        responseAllServR[i].data['orderNameAdd2'],
        responseAllServR[i].data['orderName3'],
        responseAllServR[i].data['orderNameAdd3'],
        responseAllServR[i].data['orderName4'],
        responseAllServR[i].data['orderNameAdd4'],
        responseAllServR[i].data['orderName5'],
        responseAllServR[i].data['orderNameAdd5'],
        responseAllServR[i].data['date'],
        responseAllServR[i].data['time'],
        responseAllServR[i].data['price'],
        responseAllServR[i].data['comm'],
        responseAllServR[i + 1].client['id'],
        responseAllServR[i].data['status'],
        responseAllServR[i + 1].client['Phone'],
        responseAllServR[i + 1].client['Adress'],
        responseAllServR[i + 1].client['SalonName'],
        responseAllServR[i + 1].client['img'],
      );
      arrObjectsAccept[i] = user;
    }
    dispatch(onAddAllServRM(arrObjectsAccept.reverse()));
  };

  return (
    <div className={s.wapper_request}>
      <div className={s.my_order}>Мои записи</div>

      <div className={s.inner_request}>
        {tabs.map((item, key) => (
          <div key={key}>
            <div onClick={() => setTab(key)}>
              <div className={s.menu_item_tab}> {item}</div>
              <div className={key === tab ? s.inner_request_active : null}></div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {tab === 0 && <AcceptedOrder ws={ws} statusView={4} statusText={'выполняется'} />}
        {tab === 1 && <AcceptedOrder ws={ws} statusView={3} statusText={'выполненно'} />}
        {tab === 2 && <AcceptedOrder ws={ws} statusView={2} statusText={'отказано'} />}
      </div>

      <div className={s.footer}></div>
    </div>
  );
};

export default AccepNCancelApp;
