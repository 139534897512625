import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import beautyApi from '../../../api/beautyApi';
import SignIn from '../../../companents/SignIn/SignIn';
import s from '../pageMaster.module.css';
import no_avatar from '../style/no-avatar.png';

import { confirm } from 'react-confirm-box';

import {
  onSearchItemAllServRequests,
  onScrollCatalog,
  AddCountItemAllServR,
  onAddAllServRM,
  onServMastersMenu,
  accountBeauty,
  accountMasterServices,
} from '../../../redux/action';
import uniqid from 'uniqid';
import { useHistory } from 'react-router-dom';
import ServicesOfMaster from './ServicesOfMaster';
import ModalEditServices from './ModalEditServices';
import { useRef } from 'react';

const ProfileBeautyMaster = () => {
  const urlPath = window.location.pathname;
  const dispatch = useDispatch();
  const history = useHistory();

  const [cat, setCat] = useState([]);
  const [toggleAddress, setToggleAddress] = useState(false);
  const [dataMaster, setDataMaster] = useState();
  const [dataBlog, setDataBlog] = useState();
  const [tab, setTab] = useState(0);
  const [category, setCategory] = useState(null);

  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const accId = useSelector((state) => state.accountBeauty.id);
  const { RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector(
    (state) => state.onSearchItemAllServRequests,
  );
  const AllServR = useSelector((state) => state.itemsAllServRMaster);
  const ServItemsR = useSelector((state) => state.ServItemsR);
  const websocketMaster = useSelector((state) => state.websocketMaster);

  const linkRec = useRef(null);

  const login = JSON.parse(localStorage.getItem('account'));

  const fetchCategoryes = async () => {
    const categoryes = await beautyApi.fetchCategoryesMaster();
    setCategory(categoryes);
  };

  let responseAllServR = null;
  const [data, setData] = useState(null);
  const tabs = ['услуги', 'блог', 'акции', 'отзывы'];

  const getDataMaster = async (id) => {
    const fd = new FormData();
    fd.append('id', id);
    const response = await beautyApi.MyAcceptedRequestMaster(id);
    await setDataMaster(response);
    const responseBlog = await beautyApi.fetchBlogMaster(fd);
    setDataBlog(responseBlog);
  };

  const [editOnService, setEditOnService] = useState(false);

  useEffect(() => {
    fetchCategoryes();

    if (login) {
      const regdata = new FormData();
      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 1);

      beautyApi.login(regdata).then(async (res) => {
        dispatch(accountBeauty(res[0]));
        dispatch(accountMasterServices(res[1]));
        localStorage.setItem('id_master', res[0].id);

        const master_req = await beautyApi.MasterServiceRequests(res[0].id);
        if (
          !JSON.parse(localStorage.getItem('applications_master')) ||
          !JSON.parse(localStorage.getItem('applications_master'))?.length
        ) {
          localStorage.setItem('applications_master', JSON.stringify(master_req));
        }
      });
    }

    window.scrollTo({
      top: 0,
      left: 0,
    });
    getCat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (websocketMaster) {
      websocketMaster[0]?.data.masterId === accId && setData(websocketMaster);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketMaster]);

  useEffect(() => {
    getServReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getDataMaster(accountBeautyInfo.id);
    dispatch(onServMastersMenu('Запись'));
  }, []);

  const getCat = async () => {
    const res = await beautyApi.fetchCategoryesMaster();
    setCat(res);
  };

  var arrObjects = [];
  const makeUser = (
    id,
    FIO,
    cat_id,
    orderName,
    date,
    time,
    price,
    comm,
    clientId,
    status,
    phoneClient,
    Adress,
    SalonName,
    img,
  ) => {
    return {
      id: id,
      FIO: FIO,
      cat_id: cat_id,
      orderName: orderName,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      status: status,
      phoneClient: phoneClient,
      Adress: Adress,
      SalonName: SalonName,
      img: img,
      // ...другие свойства
    };
  };

  const getServReq = async () => {
    if (data === null) {
      responseAllServR = await beautyApi.MasterServiceRequests(accountBeautyInfo.id);
    } else {
      responseAllServR = data;
    }

    for (let i = 0; i < responseAllServR.length; i = i + 2) {
      let user = makeUser(
        responseAllServR[i].data['id'],
        responseAllServR[i + 1].client['FIO'],
        responseAllServR[i].data['cat_id'],
        responseAllServR[i].data['orderName'],
        responseAllServR[i].data['date'],
        responseAllServR[i].data['time'],
        responseAllServR[i].data['price'],
        responseAllServR[i].data['comm'],
        responseAllServR[i + 1].client['id'],
        responseAllServR[i].data['status'],
        responseAllServR[i + 1].client['Phone'],
        responseAllServR[i].data['Adress'],
        responseAllServR[i].data['SalonName'],
        responseAllServR[i + 1].client['img'],
      );
      arrObjects[i] = user;
    }
    dispatch(onAddAllServRM(arrObjects.reverse()));
  };

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemAllServR(ServItemsR + 2));
    } else {
      dispatch(
        onSearchItemAllServRequests({
          RServicesValue: RServicesValue,
          RServiceEnd: RServiceEnd + 2,
        }),
      );
    }
  };

  const copyLink = async (options, shorten) => {
    const result = await confirm(shorten, options);
    if (result) {
      return;
    }
  };

  const options = {
    render: (message, onConfirm) => {
      return (
        <div className={s.wrapModalConfirm}>
          <div className={s.modalConfirm}>
            <div className={s.modalTitleConfirm} style={{ textAlign: 'center' }}>
              Выделите ссылку и нажмите скопировать!
            </div>
            <div className={s.modalConfirmMessage} style={{ marginTop: 0 }}>
              <div className={s.shortLink}>{message}</div>
            </div>
            <div className={s.modalConfirmButtonLink}>
              <button onClick={onConfirm} style={{ margin: '0 auto' }}>
                Готово
              </button>
            </div>
          </div>
        </div>
      );
    },
  };

  //Ссылка на запись действительна 2 года
  const copyLinkRec = () => {
    const link = linkRec.current.href;
    copyLink(options, link);
  };

  if (accountBeautyInfo.Email || login) {
    // статус 0 - клиент, 1 - мастер
    return (
      <>
        <div className={s.header}>
          <label htmlFor="file-input">
            <div className={s.avatar}>
              {accountBeautyInfo.img && accountBeautyInfo.img !== '' ? (
                <img
                  src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeautyInfo.img}`}
                  alt="avatar"
                />
              ) : (
                <img src={no_avatar} alt="avatar" />
              )}
            </div>
          </label>

          <div className={s.info}>
            <div className={s.name_person}>{accountBeautyInfo.FIO}</div>
            {/* <div className={s.proffesion_person}> мастер</div> */}
            <div className={s.info_subscribes}>
              <div>
                <div className={s.icon_info_balans}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.67881 6.94652C6.72117 6.93051 6.76648 6.92255 6.81212 6.9231H17.1945C17.2398 6.92276 17.2847 6.9309 17.3265 6.94706C17.3684 6.96322 17.4065 6.98707 17.4385 7.01723C17.4705 7.04738 17.4958 7.08324 17.513 7.1227C17.5302 7.16217 17.5388 7.20446 17.5384 7.2471V8.67979C17.539 8.72279 17.5306 8.76547 17.5136 8.80538C17.4966 8.84529 17.4714 8.88164 17.4394 8.91234C17.4074 8.94303 17.3693 8.96747 17.3273 8.98424C17.2853 9.00101 17.2401 9.00978 17.1945 9.01005H6.81212C6.61938 9.01005 6.46154 8.86136 6.46154 8.67979V7.2471C6.46183 7.2041 6.47114 7.16158 6.48894 7.12199C6.50675 7.08239 6.53269 7.0465 6.56527 7.01639C6.59786 6.98628 6.63644 6.96253 6.67881 6.94652ZM6.56639 10.6696C6.63198 10.609 6.72024 10.5751 6.81212 10.5753H17.1945C17.2857 10.5754 17.3731 10.6096 17.4375 10.6703C17.502 10.731 17.5383 10.8134 17.5384 10.8993V12.332C17.539 12.375 17.5306 12.4176 17.5136 12.4575C17.4966 12.4975 17.4714 12.5338 17.4394 12.5645C17.4074 12.5952 17.3693 12.6196 17.3273 12.6364C17.2853 12.6532 17.2401 12.6619 17.1945 12.6622H13.1077V18.5991C13.108 18.6417 13.0994 18.684 13.0822 18.7235C13.0651 18.7629 13.0398 18.7988 13.0078 18.8289C12.9757 18.8591 12.9377 18.883 12.8958 18.8991C12.8539 18.9153 12.809 18.9234 12.7637 18.9231H11.2362C11.0441 18.9231 10.8923 18.7801 10.8923 18.5991V12.6622H6.81212C6.61938 12.6622 6.46154 12.5135 6.46154 12.332V10.8993C6.46314 10.8127 6.5008 10.7302 6.56639 10.6696Z"
                      fill="url(#paint0_linear_1510_10471)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1510_10471"
                        x1="24.0187"
                        y1="-1.09808e-06"
                        x2="-1.30427"
                        y2="1.48822"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D432D8" />
                        <stop offset="0.515625" stopColor="#FF608D" />
                        <stop offset="1" stopColor="#F4A582" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                {Number(accountBeautyInfo.balance).toLocaleString('ru')}
              </div>
              <div>
                {/* <div className={s.icon_info_subscribes}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.31579 3.31579C3.31579 5.14389 4.80347 6.63158 6.63158 6.63158C8.45968 6.63158 9.94737 5.14389 9.94737 3.31579C9.94737 1.48768 8.45968 0 6.63158 0C4.80347 0 3.31579 1.48768 3.31579 3.31579ZM13.2632 14V13.2632C13.2632 10.4197 10.9487 8.10526 8.10526 8.10526H5.15789C2.31368 8.10526 0 10.4197 0 13.2632V14H13.2632Z"
                      fill="url(#paint0_linear_102_5637)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_102_5637"
                        x1="13.2735"
                        y1="-6.40549e-07"
                        x2="-0.725721"
                        y2="0.779426"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D432D8" />
                        <stop offset="0.515625" stopColor="#FF608D" />
                        <stop offset="1" stopColor="#F4A582" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                - */}
              </div>
              <div>
                {/* <div className={s.icon_info_subscribes}>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.263184 7C0.263184 5.14348 1.00068 3.36301 2.31344 2.05025C3.62619 0.737498 5.40667 0 7.26318 0C9.1197 0 10.9002 0.737498 12.2129 2.05025C13.5257 3.36301 14.2632 5.14348 14.2632 7C14.2632 8.85651 13.5257 10.637 12.2129 11.9497C10.9002 13.2625 9.1197 14 7.26318 14C5.40667 14 3.62619 13.2625 2.31344 11.9497C1.00068 10.637 0.263184 8.85651 0.263184 7ZM10.5588 5.7456C10.6239 5.68052 10.6755 5.60325 10.7107 5.51822C10.7459 5.43318 10.7641 5.34204 10.7641 5.25C10.7641 5.15796 10.7459 5.06682 10.7107 4.98178C10.6755 4.89675 10.6239 4.81948 10.5588 4.7544C10.4937 4.68932 10.4164 4.63769 10.3314 4.60247C10.2464 4.56724 10.1552 4.54912 10.0632 4.54912C9.97114 4.54912 9.88 4.56724 9.79497 4.60247C9.70993 4.63769 9.63267 4.68932 9.56758 4.7544L6.56318 7.7602L4.95878 6.1544C4.8937 6.08932 4.81644 6.03769 4.7314 6.00247C4.64637 5.96724 4.55522 5.94912 4.46318 5.94912C4.37114 5.94912 4.28 5.96724 4.19497 6.00247C4.10993 6.03769 4.03267 6.08932 3.96758 6.1544C3.9025 6.21948 3.85087 6.29675 3.81565 6.38178C3.78043 6.46682 3.7623 6.55796 3.7623 6.65C3.7623 6.74204 3.78043 6.83318 3.81565 6.91822C3.85087 7.00325 3.9025 7.08052 3.96758 7.1456L6.06758 9.2456C6.13261 9.31079 6.20985 9.36251 6.2949 9.3978C6.37994 9.43309 6.47111 9.45125 6.56318 9.45125C6.65526 9.45125 6.74643 9.43309 6.83147 9.3978C6.91651 9.36251 6.99376 9.31079 7.05878 9.2456L10.5588 5.7456Z"
                      fill="url(#paint0_linear_102_5638)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_102_5638"
                        x1="14.2741"
                        y1="-6.40549e-07"
                        x2="-0.497643"
                        y2="0.868128"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D432D8" />
                        <stop offset="0.515625" stopColor="#FF608D" />
                        <stop offset="1" stopColor="#F4A582" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                - */}
              </div>
              <div>
                {/* <div className={s.icon_info_subscribes}>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.26318 13V5.5M13.2632 13V1M1.26318 13V10"
                      stroke="url(#paint0_linear_102_5639)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="bevel"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_102_5639"
                        x1="13.2726"
                        y1="0.999999"
                        x2="0.611047"
                        y2="1.74411"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D432D8" />
                        <stop offset="0.515625" stopColor="#FF608D" />
                        <stop offset="1" stopColor="#F4A582" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                - */}
              </div>
              <div className={s.wrapperButtons}>
                <div className={s.wrapButtonEdit}>
                  <button
                    className={s.buttonBeauty}
                    onClick={(e) => history.push('/beauty/master/edit')}>
                    Редактировать
                  </button>
                </div>
                <div className={s.wrapButtonEdit}>
                  <button className={s.buttonBeauty} onClick={copyLinkRec} style={{ fontSize: 11 }}>
                    Ссылка для записи
                  </button>
                </div>
              </div>
              <div>
                <div className={s.icon_info_subscribes}>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.25952 0.750004L7.26031 0.750003C8.32785 0.748885 9.37789 1.02155 10.3102 1.54201C11.2425 2.06246 12.0259 2.81334 12.5857 3.723C13.1455 4.63267 13.463 5.67074 13.5077 6.73812C13.5524 7.80551 13.323 8.86653 12.8413 9.81991C12.8316 9.83895 12.8228 9.85839 12.8149 9.87819C12.6807 10.2115 12.5875 10.5599 12.537 10.9157C12.5351 10.9294 12.5336 10.9431 12.5324 10.9568C12.4859 11.4982 12.5582 11.9842 12.6908 12.4261C12.2533 12.2949 11.773 12.2206 11.2407 12.2589C11.2242 12.2601 11.2077 12.2618 11.1913 12.2641C10.8059 12.3177 10.4294 12.4218 10.0712 12.5737C10.0569 12.5797 10.0429 12.5862 10.029 12.5931C9.16836 13.0227 8.22019 13.2475 7.25845 13.25C5.60233 13.2497 4.01403 12.5914 2.84283 11.4196C1.67137 10.2475 1.01318 8.65773 1.01318 7C1.01318 5.34227 1.67137 3.75252 2.84283 2.58045C4.01428 1.40839 5.60302 0.750004 7.25952 0.750004Z"
                      // stroke="url(#paint0_linear_102_5640)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_102_5640"
                        x1="14.2741"
                        y1="-6.40549e-07"
                        x2="-0.497643"
                        y2="0.868128"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D432D8" />
                        <stop offset="0.515625" stopColor="#FF608D" />
                        <stop offset="1" stopColor="#F4A582" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <a
          href={`https://mobile.bikli.kz/beauty/master?id=${accountBeautyInfo.id}&record=true`}
          style={{ display: 'none' }}
          ref={linkRec}>
          link
        </a>

        {toggleAddress ? <div>Small slider</div> : null}
        <div className={s.content}>
          <div className={s.content_address}>
            <div className={s.content_address_geo}>
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0909 7.54545C14.0909 12.6364 7.54545 17 7.54545 17C7.54545 17 1 12.6364 1 7.54545C1 5.80949 1.68961 4.14463 2.91712 2.91712C4.14463 1.68961 5.80949 1 7.54545 1C9.28142 1 10.9463 1.68961 12.1738 2.91712C13.4013 4.14463 14.0909 5.80949 14.0909 7.54545Z"
                  stroke="url(#paint0_linear_102_5165)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.54545 9.72727C8.75044 9.72727 9.72727 8.75044 9.72727 7.54545C9.72727 6.34047 8.75044 5.36364 7.54545 5.36364C6.34047 5.36364 5.36364 6.34047 5.36364 7.54545C5.36364 8.75044 6.34047 9.72727 7.54545 9.72727Z"
                  stroke="url(#paint1_linear_102_5165)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5165"
                    x1="14.1011"
                    y1="0.999999"
                    x2="0.272843"
                    y2="1.66492"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_102_5165"
                    x1="14.1011"
                    y1="0.999999"
                    x2="0.272843"
                    y2="1.66492"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className={s.content_address_text}>{accountBeautyInfo.Adress}</div>
            {/* <div>
            {!toggleAddress ? (
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 -5.24537e-07L6 6L12 0" fill="#404246" />
              </svg>
            ) : (
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6L6 -5.24537e-07L0 6" fill="#404246" />
              </svg>
            )}
          </div> */}
          </div>
          <div className={s.tabs_master}>
            <div className={s.name_tabs}>
              {/* <div key={key} onClick={() => setTab(key)} className={key === tab && s.active_tab}> */}

              {tabs.map((item, key) => (
                <div key={key}>
                  {/* <div key={key} onClick={() => setTab(key)} className={key === tab ? s.active_tab : s.nonactive_tab}>{item}</div> */}
                  <div className={key === tab ? s.active_tab : s.nonactive_tab}>{item}</div>
                </div>
              ))}
            </div>
            <div className={s.tabs}>
              {tab === 0 ? (
                <div className={s.tab}>
                  <div className={s.servButton}>
                    <ModalEditServices page={'main'} category={category} />
                  </div>
                  <ServicesOfMaster masterID={urlPath.id} />
                </div>
              ) : tab === 1 ? (
                <div className={s.tab} style={{ marginTop: '18px' }}>
                  <div className={s.tab_blog}>
                    {dataBlog
                      ? dataBlog?.map((item, key) => (
                          <div key={key}>
                            <img
                              src={`https://bshop.kz/beauty/PublicationPhotos/${item.photopub}`}
                              alt={item.publtext}
                            />
                          </div>
                        ))
                      : Array.from(Array(9), (_, i) => (
                          <div key={i}>
                            {/* <ContentLoader
                              speed={2}
                              width={425}
                              height={433}
                              viewBox="0 0 425 433"
                              backgroundColor="#f3f3f3"
                              foregroundColor="#d6d6d6">
                              <rect x="0" y="465" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="0" y="575" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="0" y="680" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="231" y="444" rx="0" ry="0" width="41" height="1" />
                              <rect x="293" y="559" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="3" y="8" rx="0" ry="0" width="  30vw" height="30vw" />
                            </ContentLoader> */}
                          </div>
                        ))}
                  </div>
                </div>
              ) : tab === 2 ? (
                <div className={s.tab}>акции</div>
              ) : (
                <div className={s.tab}>отзывы</div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default ProfileBeautyMaster;
