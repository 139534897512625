import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Registration_text_field from './Registration_text_field';
import Inputmask from 'inputmask';
import star from '../../asseets/star.png';
import { useEffect } from 'react';

export default function ForgetPassword() {
  const history = useHistory();
  const inputP = useRef();

  const [Phone, setPhone] = useState('');
  const [phoneInput, setphoneInput] = useState(false);
  const [passwordInp, setPasswordInp] = useState('');
  const [agreePasswordInp, setAgreePasswordInp] = useState('');

  // NEW
  const [verificationCode, setVerificationCode] = useState('');
  const [serverVerificationCode, setServerVerificationCode] = useState('');
  const [tab, setTab] = useState(0);
  // ************************

  inputP.current && Inputmask({ mask: '+9 (999)-999-99-99' }).mask(inputP.current);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [tab]);

  const onPhoneInput = () => {
    // inputP.current.value = '';
    setphoneInput(true);
  };

  const wave_error_phone = () => {
    toast('Ошибка! Некорректно введенное поле Телефон', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_success = () =>
    toast('Отлично! Пароль изменен', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });

  const wave_call = () =>
    toast(
      'В течении минуты Вам позвонит робот, отвечать на звонок не нужно! Запомните последние 4 цифры и введите их в поле для кода.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
        autoClose: false,
      },
    );

  const no_agree_pass = () =>
    toast('Пароли не совпадают', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  const not_found_number_error = () =>
    toast('Такой номер не найден!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // NEW **************
  const verifyCode_error = () =>
    toast('Неправильно введен код.', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // **************************

  const PhoneCode = async () => {
    if (phoneInput == true) {
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== '_') {
          if (inputP.current.inputmask.undoValue !== '') {
            wave_call();

            setTab(1);
            const phonedata = new FormData();
            let formatted_phone = Phone;
            formatted_phone = formatted_phone.replaceAll('-', '');
            formatted_phone = formatted_phone.replaceAll(' ', '');
            formatted_phone = formatted_phone.replaceAll('(', '');
            formatted_phone = formatted_phone.replaceAll(')', '');
            formatted_phone = formatted_phone.replace('+7', '8');
            phonedata.append('phone', formatted_phone);
            phonedata.append('status', 1);
            fetch(`https://beauty.bikli.kz/beautyApi/flashcall_resetPassword.php`, {
              method: 'POST',
              body: phonedata,
            })
              .then((response) => response.json())
              .then((res) => setServerVerificationCode(res.code));
            inputP.current.setAttribute('disabled', 'disabled');
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      wave_error_phone();
    }
  };

  const SendRegForm = async (e) => {
    if (passwordInp === agreePasswordInp) {
      if (phoneInput == true) {
        if (inputP.current.inputmask.undoValue !== undefined) {
          if (inputP.current.inputmask.undoValue[17] !== '_') {
            if (inputP.current.inputmask.undoValue !== '') {
              const regdata = new FormData();

              regdata.append('phone', Phone);
              regdata.append('status', 1);
              regdata.append('password', passwordInp);

              let response = await fetch(
                `https://bshop.kz/beauty/beautyApi/profil/resetPassword.php`,
                {
                  method: 'POST',
                  body: regdata,
                },
              );
              let commits = await response.json(); // читаем ответ в формате JSON
              const PHPresponse = commits[0].message;
              console.log(commits[0].message);
              PHPresponse === 'Успешно' && wave_success();
              PHPresponse === 'Номер не найден!' && not_found_number_error();
            } else {
              wave_error_phone();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      no_agree_pass();
    }
  };

  return (
    <>
      <div className="reg_body">
        <div style={{ marginTop: '20px' }} className="reg_wrapper">
          <h1
            className="reg_title"
            style={tab === 0 ? { marginTop: '30vh' } : { marginTop: '20vh' }}>
            Восстановление пароля
          </h1>
          <div style={{ textAlign: 'left', marginTop: '30px' }} className="registration_form">
            <>
              <p style={{ textAlign: 'center', display: 'inline' }}>Телефон</p>
              <img style={{ marginBottom: '8px' }} src={star} alt="star" />
              <div className="inputGroup">
                <input
                  ref={inputP}
                  className="reg_placeholder2"
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  onClick={() => {
                    onPhoneInput();
                  }}
                />
              </div>
              <div
                style={{ textAlign: 'center', display: tab == 0 ? 'block' : 'none' }}
                className="inputGroup">
                <button
                  onClick={PhoneCode}
                  className="reg_btn"
                  style={{ marginTop: '10px', marginBottom: 20 }}>
                  Получить код
                </button>
              </div>
              <div style={{ display: tab == 1 ? 'block' : 'none' }}>
                <p style={{ display: 'inline' }}>Введите код</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="passwordCode"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  error="none"
                  placeholder="Код"
                  className="reg_placeholder2"
                  // label={"Код"}
                />
                <p style={{ display: 'inline' }}>Введите пароль</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="password"
                  onChange={(e) => setPasswordInp(e.target.value)}
                  error="none"
                  placeholder="Пароль"
                  className="reg_placeholder2"
                  // label={"Код"}
                />
                <p style={{ display: 'inline' }}>Повторите пароль</p>
                <img style={{ marginBottom: '8px' }} src={star} alt="star" />
                <Registration_text_field
                  name="password"
                  onChange={(e) => setAgreePasswordInp(e.target.value)}
                  error="none"
                  placeholder="Повторите пароль"
                  className="reg_placeholder2"
                  // label={"Код"}
                />
                <div className="inputGroup">
                  <button
                    onClick={(e) => {
                      if (verificationCode == serverVerificationCode.substr(2, 4)) {
                        SendRegForm();
                        console.log('success');
                      } else {
                        verifyCode_error();
                        console.log('error');
                      }
                    }}
                    className="reg_btn"
                    style={{ marginTop: '10px', marginBottom: 20 }}>
                    Продолжить
                  </button>
                </div>
                <div style={{ textAlign: 'center', display: 'block' }} className="inputGroup">
                  <button
                    className="reg_abs"
                    style={{ marginTop: '10px', textAlign: 'center', marginBottom: 10 }}
                    onClick={() => history.goBack()}>
                    назад
                  </button>
                </div>
              </div>
              <div
                style={{ textAlign: 'center', display: tab == 0 ? 'block' : 'none' }}
                className="inputGroup">
                <button
                  className="reg_abs"
                  style={{ marginTop: '0px', textAlign: 'center', marginBottom: 10 }}
                  onClick={() => history.goBack()}>
                  назад
                </button>
              </div>
            </>

            <div style={{ marginBottom: '130px' }}>
              <img style={{ display: 'inline' }} src={star}></img>
              <p style={{ marginLeft: '10px', display: 'inline' }}>
                поля обязательные к заполнению
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ToastContainer position="top-center" autoClose={false} theme="dark" limit={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
