import React from 'react';
import s from './Loader.module.css';

function Loader({size}) {
    Loader.defaultProps = {
        size :'small'
    }

      let classes = [
          s.loader,
          s[size],
      ]; 

  return (
    <div className={classes.join(' ')}></div>
  )
}

export default Loader;