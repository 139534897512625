import React from "react";
import s from "./Button.module.css";
import Loader from "../Loader";

function Button({children, theme, size, onClick, square, fill, isLoading, disabled}) {
  Button.defaultProps = {
      square: false,
      fill: false,
      isLoading: false,
      disabled: false,
  };

  let classes = [
      s.btn,
      s[theme || "primary"],
      s[size],
      s[square ? "square" : ""],
      s[fill ? "fill" : ""],
  ]; 
  return (
      <button disabled={disabled || isLoading} type="button" onClick={onClick} className={classes.join(" ")}>
          {isLoading ? <Loader /> : children}
      </button>
  );
  
}

export default Button;
