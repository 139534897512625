import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import beautyApi from '../../api/beautyApi';

import SignIn from '../SignIn/SignIn';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  onSearchItemAllServRequests,
  onScrollCatalog,
  AddCountItemAllServR,
  onAddAllServRM,
} from '../../redux/action';
import uniqid from 'uniqid';
import { useHistory } from 'react-router-dom';

import ItemClientRequest from '../beauty/ItemClientReq/ItemClientRequest';
import z from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';

const ProfileBeautyMaster = ({ statusView, statusText, ws }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const accId = useSelector((state) => state.accountBeauty.id);
  const { RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector(
    (state) => state.onSearchItemAllServRequests,
  );
  const AllServR = useSelector((state) => state.itemsAllServRMaster);
  const ServItemsR = useSelector((state) => state.ServItemsR);
  const websocketMaster = useSelector((state) => state.websocketMaster);

  const [cat, setCat] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const login = JSON.parse(localStorage.getItem('account'));

  let responseAllServR = null;
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    getCat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (websocketMaster) {
      websocketMaster[0]?.data.masterId === accId && setData(websocketMaster);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketMaster]);

  useEffect(() => {
    if (AllServR.length > 0) {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllServR]);

  const getCat = async () => {
    const res = await beautyApi.fetchCategoryesMaster();
    setCat(res);
  };
  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemAllServR(ServItemsR + 2));
    } else {
      dispatch(
        onSearchItemAllServRequests({
          RServicesValue: RServicesValue,
          RServiceEnd: RServiceEnd + 2,
        }),
      );
    }
  };
  if (accountBeautyInfo.Email || login) {
    // статус 0 - клиент, 1 - мастер
    return (
      <>
        <div
          className={z.all_items}
          id="scrollableDiv"
          style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
          {AllServR.length > 0 ? (
            <InfiniteScroll
              style={{}}
              dataLength={AllServR?.length}
              next={fetchNewItems}
              hasMore={true}
              loader={<div style={{ width: '50%' }}></div>}
              sscrollableTarget="scrollableDiv">
              {AllServR.map((item) => (
                <ItemClientRequest
                  statusText={statusText}
                  statusView={statusView}
                  key={uniqid()}
                  cat={cat}
                  ws={ws}
                  {...item}
                />
              ))}
            </InfiniteScroll>
          ) : (
            Array.from(Array(8), (_) => <ServicesLoadingBlock key={uniqid()} />)
          )}
        </div>

        <div className={z.footer}></div>
      </>
    );
  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default ProfileBeautyMaster;
