/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { accountBeauty, accountMasterServices } from '../../redux/action';
import { useHistory } from 'react-router-dom';
import beautyApi from '../../api/beautyApi';

import AppStore from '../../asseets/app-store 1.png';
import GooglePlay from '../../asseets/google-play 1.png';

const SignInFromInstagram = () => {
  const dispatch = useDispatch();
  const [Phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [PhoneError] = useState('');
  const [showPopUpPartner, setshowPopUpPartner] = useState(false);
  // const [showFormPartner, setshowFormPartner] = useState(false);
  const time = localStorage.getItem('time');

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return () => (document.querySelector('body').style.overflow = 'auto');
  }, []);

  const history = useHistory();

  const wave_error = () => {
    if (Phone === '') {
      toast('Ошибка! Пустое поле Телефона', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (Boolean(PhoneError) === true) {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password === '') {
      toast('Ошибка! Пустое поле пароль', {
        backgroundColor: '#323131',
        color: '#ffffff',
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const wave_success = () =>
    toast('Отлично! Вы успешно авторизовались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const wave_error_sign_in = () =>
    toast.error('Ошибка входа! Вы ввели  неверные данные!', {
      backgroundColor: '#323131',
      color: '#ffffff',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const passwordd = (event) => {
    setPassword(event.target.value);
  };

  const onPhoneChange = (event) => {
    setPhone(event.target.value);

    var old = 0;

    var curLen = event.target.value.length;

    if (curLen < old) {
      old--;
      return;
    }

    if (curLen === 1) event.target.value = event.target.value = '+7 (';

    if (curLen === 7) event.target.value = event.target.value + ')-';

    if (curLen === 12) event.target.value = event.target.value + '-';

    if (curLen === 15) event.target.value = event.target.value + '-';

    if (curLen > 18) event.target.value = event.target.value.substring(0, 17);
    old++;
  };

  const SendRegForm = async (e) => {
    e.preventDefault();
    if (Boolean(PhoneError) === false && Phone.length === 18) {
      const regdata = new FormData();
      var now = String(new Date().getTime());
      console.log(now.substr(0, 10));
      time ? console.log('уже есть') : localStorage.setItem('time', now.substr(0, 10));
      regdata.append('phone', Phone);
      regdata.append('pass', password);

      let response = await beautyApi.login(regdata);

      let commits = await response; // читаем ответ в формате JSON
      if (commits) {
        wave_success();
        dispatch(accountBeauty(commits[0]));
        dispatch(accountMasterServices(commits[1]));
        localStorage.setItem('account', JSON.stringify({ login: Phone, pass: password }));
        history.push('/beauty/requests');
        window.location.reload();
      } else {
        wave_error_sign_in();
      }
    } else {
      wave_error();
    }
  };

  const goToReg = () => {
    history.push('/beauty/registration');
  };
  return (

    <><div className="preview">
          <a
              style={{
                  paddingTop: '8px',
                  textDecoration: `none`,
                  marginLeft: '0px',
                  marginRight: '20px',
                  position: 'relative',
                  bottom: '16px',
                  color: 'floralwhite',
              }}>
              Скачать приложение
          </a>

          <a href="https://apps.apple.com/us/app/partner-bikli/id1638081565">
              <img style={{ marginTop: '8px', display: 'inline', marginLeft: '30px' }} src={AppStore} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=kz.bikli.mobile">
              <img style={{ display: 'inline', marginLeft: '48px' }} src={GooglePlay} />
          </a>
      </div>
      <div className="reg_body">
              <div className="reg_wrapper">
                  <div className="inner_text" style={{ marginTop: '80px' }}>
                      <div className="inner_text_title"> Клиенты уже ждут тебя! </div>
                      <div className="inner_text_subtitle">Пройди регистрацию и принимай заявки!</div>
                      <button className="reg_abs" style={{ textAlign: 'center' }} onClick={() => goToReg()}>
                          Зарегистрироваться
                      </button>
                  </div>
              </div>

              {showPopUpPartner ? (
                  <div className="popup-info-partner">
                      <button className="btn_info_for_partners" onClick={() => setshowPopUpPartner(false)}>
                          Закрыть
                      </button>

                      <div className="popup-info-partner-text">
                          <p>
                              Мобильное приложение Partner Bikli - это удобный сервис для планирования вашей работы.
                          </p>
                          <p>Сервис включает: </p>
                          <p>- личный кабинет;</p>
                          <p>- запись клиентов;</p>
                          <p>- публикации работ.</p>
                          <p>Вам не нужно больше заниматься поиском клиентов, приложение это сделает за вас.</p>
                          <p>С помощью данного приложения вы можете:</p>
                          <p>- планировать своё рабочее время;</p>
                          <p>- посмотреть свои записи;</p>
                          <p>- найти новые заказы;</p>
                          <p>- вести портфолио.</p>
                          <p>Контакты технической поддержки и обратной связи: </p>
                          <p>info.bshop.kz@gmail.com</p>
                          <p>тел. +7-718-266-50-90</p>
                      </div>
                  </div>
              ) : null}
              <button className="btn_info_for_partners" onClick={() => setshowPopUpPartner(true)}>
                  Помощь
              </button>
              <ToastContainer
                  position="top-center"
                  autoClose={500}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover />
          </div></>
  );
};

export default SignInFromInstagram;
