import s from './addRecordForm.module.css';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import beautyApi from '../../../api/beautyApi';

const AddRecordForm = ({ setAddRecord, masterId }) => {

    const [subServices, setSubServices] = useState(null);
    const [services, setServices] = useState(null);
    const [category, setCategory] = useState(null);


    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ mode: "onChange" });

    const onSubmit = async (formData) => {

        const data = new FormData();
        data.append('clientName', formData.clientName);
        data.append('master', masterId);
        data.append('selectedCat', formData.selectedCat.split(' ').splice(1).join(' '));
        data.append('selectedServices', formData.selectedServices.split(' ').splice(1).join(' '));
        data.append('price', formData.price);
        data.append('comm', null);
        data.append('date', formData.date);
        data.append('time', formData.time);
        data.append('DopselectedServices', formData.DopselectedServices.split(' ').splice(1).join(' '));
        await beautyApi.entryPersonal(data)

        setAddRecord(false);
        reset()
    }
    const servicesMaster = useSelector((state) => state.ServicesMaster);

    const telInput = (e) => {
        let x = e.target.value.replace(/\D/g, '')
            .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,3})/);
        if (!x[1]) {
            e.target.value = '+';
            return;
        }
        if (!x[2]) {
            e.target.value = `+${x[1]}`;
            return;
        }
        e.target.value = `+${x[1]} (${x[2]}`
            + (x[3] ? `) ${x[3]}` : '')
            + (x[4] ? ` - ${x[4]}` : '')
            + (x[5] ? ` - ${x[5]}` : '');
    }

    const fetchServices = async (value) => {
        const fd = new FormData();
        fd.append('prof_id', value);
        const categoryes = await beautyApi.fetchServicesMaster(fd);
        setServices(categoryes);
    };

    const fetchSubServices = (value) => {
        const tservices = beautyApi.fetchServicesInReq3(value).then((res) => {
            return res;
        });
        tservices.then((res) => setSubServices(res));
    };

    const fetchCategoryes = async () => {
        const categoryes = await beautyApi.fetchCategoryesMaster();
        setCategory(categoryes);
    };

    useEffect(() => {
        fetchCategoryes();
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    const handleServices = (value) => {
        fetchServices(value);
    };

    const handleSubServices = (value) => {
        fetchSubServices(value);
    };


    return (
        <div className={s.wrapperModal}>

            <div className={s.card}>
                <button
                    className={s.btnExit}
                    onClick={() => {
                        setAddRecord(false);
                    }}
                >
                    ✕
                </button>
                <h2 className={s.title}>Добавить запись</h2>

                <form noValidate action="" onSubmit={handleSubmit(onSubmit)}>
                    <div className={s.form}>
                        <div className={s.row}>
                            <label htmlFor="clientName">Клиент</label>
                            <input type="text" name="clientName" id="clientName" placeholder="Введите имя"
                                {...register("clientName", {
                                    required: 'Поле обязательно к заполнению',
                                    minLength: {
                                        value: 2,
                                        message: 'Имя должно быть больше 1 символа'
                                    },
                                })} />

                            {errors?.clientName?.message && <p className={s.inputError}>&#9888; {errors?.clientName?.message}</p>}
                        </div>

                        <div className={s.row}>
                            <label htmlFor="phone">Номер телефона</label>
                            <input type="tel" name="phone" id="phone" placeholder="+7"
                                onInput={telInput}
                                {...register('phone', {
                                    required: 'Поле обязательно к заполнению',
                                    minLength: {
                                        value: 22,
                                        message: 'Введите существующий номер'
                                    }
                                })} />

                            {errors?.phone?.message && <p className={s.inputError}>&#9888; {errors?.phone?.message}</p>}
                        </div>

                        <div className={s.row}>

                            <label htmlFor="selectedCat">Услуги</label>
                            <select
                                defaultValue='0'
                                name="selectedCat"
                                id="selectedCat"
                                {...register('selectedCat', {
                                    required: 'Поле обязательно к заполнению',
                                })}
                                onChange={(e) => handleServices(+(e.target.value).split(' ')[0])}>

                                <option value=''>Выберите из списка</option>

                                {servicesMaster?.map((item) => (
                                    <option key={item.cat_id} value={item.cat_id + ` ${item.cat_name}`}>
                                        {item.cat_name}
                                    </option>
                                ))}
                            </select>
                            {errors?.selectedCat?.message && <p className={s.inputError}>&#9888; {errors?.selectedCat?.message}</p>}
                        </div>

                        <div className={s.row}>

                            <label htmlFor="selectedServices">Категории</label>
                            <select
                                name="selectedServices"
                                id="selectedServices"
                                {...register('selectedServices', {
                                    required: 'Поле обязательно к заполнению',
                                })}
                                onChange={(e) => { handleSubServices(+(e.target.value).split(' ')[0]) }}>

                                <option value="">Выберите из списка</option>

                                {services?.map((item) => (
                                    <option key={item.id} value={item.id + ` ${item.prof_add}`}>
                                        {item.prof_add}
                                    </option>
                                ))}
                            </select>

                            {errors?.selectedServices?.message && <p className={s.inputError}>&#9888; {errors?.selectedServices?.message}</p>}
                        </div>

                        <div className={s.row}>

                            <label htmlFor="DopselectedServices">Подкатегории</label>
                            <select name="DopselectedServices" id="DopselectedServices" {...register('DopselectedServices', {
                                required: 'Поле обязательно к заполнению',
                            })}>

                                <option value="">Выберите из списка</option>

                                {subServices?.map((item) => (
                                    <option key={item.id} value={item.id + ` ${item.prof_add_dop}`}>
                                        {item.prof_add_dop}
                                    </option>
                                ))}
                            </select>

                            {errors?.DopselectedServices?.message && <p className={s.inputError}>&#9888; {errors?.DopselectedServices?.message}</p>}
                        </div>

                        <div className={s.row}>
                            <label htmlFor="date">Дата</label>
                            <input type="date" name="date" id="date"
                                {...register('date', {
                                    required: 'Поле обязательно к заполнению',
                                })} />

                            {errors?.date?.message && <p className={s.inputError}>&#9888; {errors?.date?.message}</p>}
                        </div>

                        <div className={s.row}>
                            <label htmlFor="time">Время</label>
                            <input type="time" name="time" id="time"
                                {...register('time', {
                                    required: 'Поле обязательно к заполнению',
                                })} />

                            {errors?.time?.message && <p className={s.inputError}>&#9888; {errors?.time?.message}</p>}
                        </div>

                        <div className={s.row}>
                            <label htmlFor="price">Стоимость</label>

                            <div className="wrapper">
                                <input type="number" name="price" id="price"
                                    {...register('price', {
                                        required: 'Поле обязательно к заполнению',
                                    })} />
                                <span>тг</span>

                                {errors?.price?.message && <p className={s.inputError}>&#9888; {errors?.price?.message}</p>}
                            </div>
                        </div>
                    </div>

                    <button type='submit'
                        className={`${s.btn} ${s.btnFill} ${s.btnSubmit}`}>Отправить</button>

                </form>
            </div>
        </div >
    )
}

export default AddRecordForm