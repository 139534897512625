import React from 'react';
import { useSelector } from 'react-redux';
import s from './header.module.css';
import { RiNotificationOffLine } from 'react-icons/ri';
import { TbBellRinging } from 'react-icons/tb';
import { useHistory } from 'react-router-dom';
import beautyApi from '../../api/beautyApi';
const Notifications = ({ id }) => {
  const history = useHistory();

  const websocketMasterR = useSelector((state) => state.websocketMaster);

  const notifAllApp = Number(localStorage.getItem('count_app'));
  const notif = JSON.parse(localStorage.getItem('notification_master'));

  const clickNotif = async () => {
    if (websocketMasterR.length) {
      localStorage.setItem('applications_master', JSON.stringify(websocketMasterR));
    } else {
      const master_req = await beautyApi.MasterServiceRequests(id);
      localStorage.setItem('applications_master', JSON.stringify(master_req));
    }
    history.push('/beauty/acceptncanceled');
    localStorage.removeItem('notification_master');
  };
  const clickNotifAll = () => {
    history.push('/beauty/requests');
    localStorage.removeItem('count_app');
  };
  return (
    <div className={s.rootNotification}>
      <>
        {notifAllApp > 0 && notifAllApp !== null && (
          <div className={s.notification_wrapper} onClick={() => clickNotifAll()}>
            <div className={s.notifIcon}>
              <TbBellRinging />
            </div>
            <div>
              <div style={{ marginBottom: '5px', display: 'inline-block' }}>
                Количество новых заявок:
              </div>
              <span
                style={{
                  borderBottom: '1px solid black',
                  fontWeight: 700,
                  marginTop: '5px',
                  marginLeft: '5px',
                  display: 'inline-block',
                }}>
                {notifAllApp}
              </span>
            </div>
          </div>
        )}
        {notif?.map(
          (item, key) =>
            item && (
              <div key={item?.id} className={s.notification_wrapper} onClick={() => clickNotif()}>
                <div className={s.notifIcon}>
                  <TbBellRinging />
                </div>
                <div>
                  <div style={{ marginBottom: '5px' }}>
                    <div style={{ fontWeight: 700, marginBottom: '5px' }}>{item?.FIO}</div>{' '}
                    ответил/а на ваше предложение :
                  </div>
                  <span
                    style={{
                      borderBottom: '1px solid black',
                      fontWeight: 700,
                      marginTop: '5px',
                    }}>
                    {item?.orderName}
                  </span>
                </div>
              </div>
            ),
        )}
        {!notif ? (
          notifAllApp === 0 ? (
            <div className={s.notifNone}>
              <div className={s.notifNoneIcon}>
                <RiNotificationOffLine />
              </div>
              Нет уведомлений
            </div>
          ) : null
        ) : null}
      </>
    </div>
  );
};

export default Notifications;
