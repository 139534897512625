import AddRecordForm from '../../forms/AddRecordForm/AddRecordForm';
import ItemClientRequest from '../../beauty/ItemClientReq/ItemClientRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import beautyApi from '../../../api/beautyApi';
import s from './schedule.module.css';
import { useState } from 'react';
import { useEffect } from 'react';
import SchedulePopup from './SchedulePopup';

const ScheduleRecord = () => {
  const options = { month: 'long', day: 'numeric' };

  const master = useSelector((state) => state.accountBeauty);

  const { dateType1, dateType2 } = useSelector((state) => state.date);

  const [viewClient, setViewClient] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [client, setClient] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [addRecord, setAddRecord] = useState(false);

  const [time, setTime] = useState('');
  const [schedule, setSchedule] = useState([]);

  const [dataTime, setDataTime] = useState([]);
  const [cards, setCards] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [acceptDate] = useState(new Date().toISOString().slice(0, 10));
  const [acceptDefDate] = useState(new Date().toLocaleDateString('ru-ru', options));

  useEffect(() => {
    getSchedule(master.id, acceptDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIndividualEntry(master.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRecord]);

  useEffect(() => {
    dateType2 !== '-' && getSchedule(master.id, dateType2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType2]);

  const addTime = async (e) => {
    e.preventDefault();
    if (time !== '') {
      const fd = new FormData();
      fd.append('idMaster', master.id);
      fd.append('time', time);
      fd.append('date', dateType1 === '-' ? acceptDate : dateType2);
      fd.append('date2', dateType1 === '-' ? acceptDefDate : dateType1);
      await beautyApi.Schedule(fd);
      getSchedule(master.id, dateType2);
    }
    setTime('');
  };

  const deleteTime = (id, idMaster) => {
    const body = JSON.stringify({
      id: id,
      idMaster: idMaster,
    });
    const res = beautyApi.deleteTime(body).then((res) => {
      if (res === 1) {
        getSchedule(master.id, dateType2);
      }
    });
  };

  const getSchedule = (id, date) => {
    const today = date === '-' ? acceptDate : date;
    const fd = new FormData();
    fd.append('idMaster', id);
    fd.append('date', today);
    const res = beautyApi.getSchedule(fd);
    res.then((res) => setSchedule(res));
  };

  const getIndividualEntry = async (id) => {
    const fd = new FormData();
    fd.append('master_id', id);
    let response = await beautyApi.getIndividualEntry(fd);
    let message = await response;
    setCards(message);
  };

  const viewApplicaitons = (master, date, time, id) => {
    setDataTime({ id: id, date: date, time: time });
    const fd = new FormData();
    fd.append('master', master);
    fd.append('date', date);
    fd.append('time', time);
    const res = beautyApi.getPersonalClient(fd);
    res.then((res) => {
      if (!isEdit) {
        setViewClient(true);
        setClient(res[0]);
      }
    });
  };

  const allTodySchedule = schedule.length ? (
    schedule.map((item, i) => {
      switch (item.status) {
        case '0': {
          return (
            <div className={s.itemTime} key={i}>
              {isEdit ? (
                <span
                  style={{ color: 'red', marginRight: '8px', fontSize: '18px' }}
                  onClick={() => deleteTime(item.id, item.idMaster)}>
                  &#10006;
                </span>
              ) : (
                <></>
              )}
              <span>{item.time}</span>
            </div>
          );
        }
        case '1': {
          return (
            <div
              className={s.itemTimeActive}
              key={i}
              onClick={() => {
                setIsButtonVisible(true);
                setIsDone(false);
                viewApplicaitons(item.idMaster, item.date, item.time, item.id);
              }}>
              {isEdit ? (
                <span
                  style={{ color: 'red', marginRight: '8px', fontSize: '18px' }}
                  onClick={() => deleteTime(item.id, item.idMaster)}>
                  &#10006;
                </span>
              ) : (
                <></>
              )}

              <span>{item.time}</span>
            </div>
          );
        }
        case '2': {
          return (
            <div
              className={s.itemTimeAccepted}
              key={i}
              onClick={() => {
                setIsButtonVisible(false);
                setIsDone(false);
                viewApplicaitons(item.idMaster, item.date, item.time, item.id);
              }}>
              {isEdit ? (
                <span
                  style={{ color: 'red', marginRight: '8px', fontSize: '18px' }}
                  onClick={() => deleteTime(item.id, item.idMaster)}>
                  &#10006;
                </span>
              ) : (
                <></>
              )}

              <span>{item.time}</span>
            </div>
          );
        }
        case '3': {
          return (
            <div
              className={s.itemTimeDone}
              key={i}
              onClick={() => {
                setIsButtonVisible(false);
                setIsDone(true);
                viewApplicaitons(item.idMaster, item.date, item.time, item.id);
              }}>
              {isEdit ? (
                <span
                  style={{ color: 'red', marginRight: '8px', fontSize: '18px' }}
                  onClick={() => deleteTime(item.id, item.idMaster)}>
                  &#10006;
                </span>
              ) : (
                <></>
              )}

              <span>{item.time}</span>
            </div>
          );
        }
        default:
          break;
      }
    })
  ) : (
    <div>Время не задано</div>
  );

  if (addRecord) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <div>
      <div>
        <>
          <form action="" className={s.wrapForm}>
            <div className={s.wrapperTimeInput}>
              <input
                type="time"
                placeholder="09:00"
                value={time}
                className={s.inputTime}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <button className={s.addTime} onClick={addTime}>
              Добавить время
            </button>
          </form>
          <div className={s.date}>
            {dateType1 === '-' ? acceptDefDate : dateType1}
            <span
              className={isEdit ? s.editButton : s.editButtonActive}
              onClick={() => {
                setIsEdit(!isEdit);
              }}>
              ✎
            </span>
          </div>
          <div className={s.wrapperTime}>{allTodySchedule}</div>

          <div className={s.wrapperButtons}>
            {/* <button className={`${s.btn} ${s.btnOutline}`} data="Выходной день"></button> */}
            <button className={`${s.btn} ${s.btnFill}`} onClick={() => setAddRecord(true)}>
              Добавить запись
            </button>
          </div>
          <div className={s.wrapper}></div>
        </>
      </div>
      {addRecord && <AddRecordForm setAddRecord={setAddRecord} masterId={master.id} />}

      {viewClient && (
        <SchedulePopup
          master={master}
          setViewClient={setViewClient}
          setClient={setClient}
          dataTime={dataTime}
          getSchedule={getSchedule}
          dateType2={dateType2}
          client={client}
          isButtonVisible={isButtonVisible}
          isDone={isDone}
        />
      )}
      <div className={s.personalRec}>Личные записи</div>
      <div className={s.wrapper}>
        <InfiniteScroll
          style={{ display: 'flex', flexWrap: 'wrap', columnGap: '6%', justifyContent: 'center' }}
          dataLength={cards.length}
          hasMore={true}
          loader={<div style={{ width: '50%' }}></div>}
          sscrollableTarget="scrollableDiv">
          {cards.length > 0 ? (
            cards.map((item) => {
              return <ItemClientRequest key={uniqid()} {...item} />;
            })
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ScheduleRecord;
