import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import SignIn from '../../companents/SignIn/SignIn';
import ProfileBeautyMaster from './ProfileBeautyMaster/ProfileBeautyMaster';

const Profile = () => {
  const login = JSON.parse(localStorage.getItem('account'));
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  if (accountBeautyInfo.Email || login) {
    // статус 0 - клиент, 1 - мастер
    if (accountBeautyInfo.status === '1') {
      return <ProfileBeautyMaster />;
    } else {
      return '';
    }
  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default Profile;
